export const constCategories = [
  {
    id: 'sam-2025-motocross-mx-2-u-20',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'MX2 U20',
    commonNameEn: 'MX2 U20',
    commonNameFr: 'MX2 U20',
    importName: 'MX MX2 U20',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'MX2',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1037,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'MX2 U20',
        nameEn: 'MX2 U20',
        nameFr: 'MX2 U20',
        shortNameDe: 'MX2 U20',
        shortNameEn: 'MX2 U20',
        shortNameFr: 'MX2 U20',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MX2 U20',
        startListName: 'MX2',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 320, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe:
          '85 bis 250 ccm 2/4-Takt, bis 20 Jahre (Jahrgang 2005 und jünger), nicht mit einer Swiss Moto-MX-Lizenz (Ausnahme: Yamaha-Cup)',
        hintEn:
          '85 to 250 cc 2/4-stroke, up to 20 years old (born 2005 and younger), not with a Swiss Moto MX license (exception: Yamaha Cup)',
        hintFr:
          "85 à 250 cc 2/4 temps, jusqu'à 20 ans (nés en 2005 et moins), sans permis Suisse Moto MX (exception : Yamaha Cup)",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-motocross-mx-2-u-25',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'MX2 U25',
    commonNameEn: 'MX2 U25',
    commonNameFr: 'MX2 U25',
    importName: 'MX MX2 U25',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'MX2',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1038,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'MX2 U25',
        nameEn: 'MX2 U25',
        nameFr: 'MX2 U25',
        shortNameDe: 'MX2 U25',
        shortNameEn: 'MX2 U25',
        shortNameFr: 'MX2 U25',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MX2 U25',
        startListName: 'MX2',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 420, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe:
          '85 bis 250 ccm 2/4-Takt, bis 25 Jahre (Jahrgänge 2000 bis 2004), nicht mit einer Swiss Moto-MX-Lizenz (Ausnahme: Yamaha-Cup)',
        hintEn:
          '85 to 250 cc 2/4-stroke, up to 25 years old (born 2000 to 2004), not with a Swiss Moto MX license (exception: Yamaha Cup)',
        hintFr:
          "85 à 250 cc 2/4 temps, jusqu'à 25 ans (nés entre 2000 et 2004), sans permis Suisse Moto MX (exception : Yamaha Cup)",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-motocross-mx-2-unter-30',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'MX2 U30',
    commonNameEn: 'MX2 U30',
    commonNameFr: 'MX2 U30',
    importName: 'MX MX2 U30',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'MX2',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1039,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'MX2 U30',
        nameEn: 'MX2 U30',
        nameFr: 'MX2 U30',
        shortNameDe: 'MX2 U30',
        shortNameEn: 'MX2 U30',
        shortNameFr: 'MX2 U30',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MX2 U30',
        startListName: 'MX2',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 420, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe:
          '85 bis 250 ccm 2/4-Takt, 26 bis 30 Jahre (Jahrgänge 1995-1999), nicht mit einer Swiss Moto-MX-Lizenz (Ausnahme: Yamaha-Cup)',
        hintEn:
          '85 to 250 ccm 2/4-stroke, 26 to 30 years (born 1994-1998), not with a Swiss Moto (FMS) MX license (exception: Yamaha-Cup)',
        hintFr:
          '85 à 250 ccm 2/4-Stroke, 26 à 30 ans (né en 1994-1998), pas avec une licence Swiss Moto (FMS) MX (exception: Yamaha-Cup)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-motocross-mx-2-uber-30',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'MX2 Ü30',
    commonNameEn: 'MX2 Ü30',
    commonNameFr: 'MX2 Ü30',
    importName: 'MX MX2 Ü30',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'MX2',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1040,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'MX2 Ü30',
        nameEn: 'MX2 Ü30',
        nameFr: 'MX2 Ü30',
        shortNameDe: 'MX2 Ü30',
        shortNameEn: 'MX2 Ü30',
        shortNameFr: 'MX2 Ü30',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MX2 Ü30',
        startListName: 'MX2',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 420, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe:
          '85 bis 250 ccm 2/4-Takt, ab 31 Jahre (Jahrgänge 1994 und älter), nicht mit einer Swiss Moto-MX-Lizenz (Ausnahme: Yamaha-Cup)',
        hintEn:
          '85 to 250 cc 2/4-stroke, aged 31 and over (born 1994 and older), not with a Swiss Moto MX license (exception: Yamaha Cup)',
        hintFr:
          '85 à 250 cc 2/4 temps, à partir de 31 ans (nés en 1994 et plus), sans permis Suisse Moto MX (exception : Yamaha Cup)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-motocross-mx-open-unter-30',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'MX Open U30',
    commonNameEn: 'MX Open U30',
    commonNameFr: 'MX Open U30',
    importName: 'MX MX Open U30',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'MX Open',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1041,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'MX Open U30',
        nameEn: 'MX Open U30',
        nameFr: 'MX Open U30',
        shortNameDe: 'MX Open U30',
        shortNameEn: 'MX Open U30',
        shortNameFr: 'MX Open U30',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MX Open U30',
        startListName: 'MX Open',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 420, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe:
          '85 bis 650 ccm 2/4-Takt, bis 30 Jahre (Jahrgang 1995 und jünger), nicht mit einer Swiss Moto-MX-Lizenz (Ausnahme: Yamaha-Cup)',
        hintEn:
          '85 to 650 cc 2/4-stroke, up to 30 years old (born 1995 and younger), not with a Swiss Moto MX license (exception: Yamaha Cup)',
        hintFr:
          "85 à 650 cc 2/4 temps, jusqu'à 30 ans (nés en 1995 et moins), sans permis Suisse Moto MX (exception : Yamaha Cup)",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-motocross-mx-open-uber-30',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'MX Open Ü30',
    commonNameEn: 'MX Open Ü30',
    commonNameFr: 'MX Open Ü30',
    importName: 'MX MX Open Ü30',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'MX Open',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1042,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'MX Open Ü30',
        nameEn: 'MX Open Ü30',
        nameFr: 'MX Open Ü30',
        shortNameDe: 'MX Open Ü30',
        shortNameEn: 'MX Open Ü30',
        shortNameFr: 'MX Open Ü30',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MX Open Ü30',
        startListName: 'MX Open',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 420, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe:
          '85 bis 650 ccm 2/4-Takt, ab 31 Jahre (Jahrgänge 1994 und älter), nicht mit einer Swiss Moto-MX-Lizenz (Ausnahme: Yamaha-Cup)',
        hintEn:
          '85 to 650 cc 2/4-stroke, aged 31 and over (born 1994 and older), not with a Swiss Moto MX license (exception: Yamaha Cup)',
        hintFr:
          '85 à 650 cc 2/4 temps, à partir de 31 ans (nés en 1994 et plus), sans permis Suisse Moto MX (exception : Yamaha Cup)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-motocross-mx-ladys-open',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Ladys Open',
    commonNameEn: 'Ladys Open',
    commonNameFr: 'Ladys Open',
    importName: 'MX MX Ladys Open',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Ladys Open',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1043,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Ladys Open',
        nameEn: 'Ladys Open',
        nameFr: 'Ladys Open',
        shortNameDe: 'Ladys Open',
        shortNameEn: 'Ladys Open',
        shortNameFr: 'Ladys Open',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Ladys Open',
        startListName: 'Ladys Open',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 320, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'ab 85 ccm 2 Takt bis Open, ab 13 Jahre (Jahrgang 2012)',
        hintEn: 'from 85 cc 2-stroke to open, from 13 years (born 2012)',
        hintFr: 'de 85 cc 2 temps à open, à partir de 13 ans (né en 2012)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-motocross-senioren-open-u-40',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Senioren Open Ü40',
    commonNameEn: 'Senioren Open Ü40',
    commonNameFr: 'Senioren Open Ü40',
    importName: 'MX Senioren Open Ü40',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Senioren Open',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1044,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Senioren Open Ü40',
        nameEn: 'Senioren Open Ü40',
        nameFr: 'Senioren Open Ü40',
        shortNameDe: 'Senioren Open Ü40',
        shortNameEn: 'Senioren Open Ü40',
        shortNameFr: 'Senioren Open Ü40',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Senioren Open Ü40',
        startListName: 'Senioren Open',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 420, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Ab 40 Jahre (Jahrgänge 1976-1985)',
        hintEn: 'From 40 years old (born 1976-1985)',
        hintFr: 'A partir de 40 ans (né en 1976-1985)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-motocross-senioren-open-u-50',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Senioren Open Ü50',
    commonNameEn: 'Senioren Open Ü50',
    commonNameFr: 'Senioren Open Ü50',
    importName: 'MX Senioren Open Ü50',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Senioren Open',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1045,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Senioren Open Ü50',
        nameEn: 'Senioren Open Ü50',
        nameFr: 'Senioren Open Ü50',
        shortNameDe: 'Senioren Open Ü50',
        shortNameEn: 'Senioren Open Ü50',
        shortNameFr: 'Senioren Open Ü50',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Senioren Open Ü50',
        startListName: 'Senioren Open',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 420, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Ab 50 Jahre (Jahrgänge 1966-1975)',
        hintEn: 'From 50 years old (born 1966-1975)',
        hintFr: 'A partir de 50 ans (né en 1966-1975)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-motocross-senioren-open-u-60',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Senioren Open Ü60',
    commonNameEn: 'Senioren Open Ü60',
    commonNameFr: 'Senioren Open Ü60',
    importName: 'MX Senioren Open Ü60',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Senioren Open',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1046,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Senioren Open Ü60',
        nameEn: 'Senioren Open Ü60',
        nameFr: 'Senioren Open Ü60',
        shortNameDe: 'Senioren Open Ü60',
        shortNameEn: 'Senioren Open Ü60',
        shortNameFr: 'Senioren Open Ü60',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Senioren Open Ü60',
        startListName: 'Senioren Open',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 420, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Ab 60 Jahre (Jahrgang 1965 und älter)',
        hintEn: 'From 60 years (born 1965 and older)',
        hintFr: 'A partir de 60 ans (nés en 1965 et plus)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-motocross-masters-open',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Masters Open',
    commonNameEn: 'Masters Open',
    commonNameFr: 'Masters Open',
    importName: 'MX Masters Open',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Masters Open',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1047,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Masters Open',
        nameEn: 'Masters Open',
        nameFr: 'Masters Open',
        shortNameDe: 'Masters Open',
        shortNameEn: 'Masters Open',
        shortNameFr: 'Masters Open',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Masters Open',
        startListName: 'Masters Open',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 470, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Nicht mit Inter-Lizenz eines anderen Verbandes',
        hintEn: 'Not with an inter-license from another association',
        hintFr: "Pas avec une inter-licence d'une autre association",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-motocross-pro-race-serie',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'ProRace Serie Volllizenz',
    commonNameEn: 'ProRace Serie Volllizenz',
    commonNameFr: 'ProRace Serie Volllizenz',
    importName: 'MX ProRace Serie Volllizenz',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'ProRace Serie',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1048,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'ProRace Serie Volllizenz',
        nameEn: 'ProRace Serie Volllizenz',
        nameFr: 'ProRace Serie Volllizenz',
        shortNameDe: 'ProRace Serie',
        shortNameEn: 'ProRace Serie',
        shortNameFr: 'ProRace Serie',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'ProRace Serie',
        startListName: 'ProRace Serie',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 470, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-motocross-pro-race-serie-zusatz',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'ProRace Serie Zusatz',
    commonNameEn: 'ProRace Serie Zusatz',
    commonNameFr: 'ProRace Serie Zusatz',
    importName: 'MX ProRace Serie Zusatz',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: 'ProRace Serie',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1049,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'ProRace Serie Zusatz',
        nameEn: 'ProRace Serie Zusatz',
        nameFr: 'ProRace Serie Zusatz',
        shortNameDe: 'ProRace Serie Zusatz',
        shortNameEn: 'ProRace Serie Zusatz',
        shortNameFr: 'ProRace Serie Zusatz',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'ProRace Serie',
        startListName: 'ProRace Serie',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 120, licenseType: 'national' }],
        priceHintDe: 'ohne erweiterte Versicherungsdeckung',
        priceHintEn: 'without extended insurance coverage',
        priceHintFr: "sans couverture d'assurance complémentaire",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe:
          'Für Besitzer einer Lizenz Swiss Moto Inter, Swiss Moto National, Swiss Moto Junioren, SAM Masters',
        hintEn:
          'For owners of a Swiss Moto Inter, Swiss Moto National, Swiss Moto Juniors, SAM Masters license',
        hintFr:
          "Pour les titulaires d'une licence Swiss Moto Inter, Swiss Moto National, Swiss Moto Juniors, SAM Masters",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: false,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2025-motocross-sidecar-pilot',
    active: true,
    archived: false,
    sidecar: true,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF', 'MyLaps'],
    numberChoice: true,
    commonNameDe: 'Sidecar CS Pilot',
    commonNameEn: 'Sidecar CS Pilot',
    commonNameFr: 'Sidecar CS Pilot',
    importName: 'Swiss MX Sidecar CS Pilot',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Sidecar',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1050,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss MX Sidecar CS Pilot',
        nameEn: 'Swiss MX Sidecar CS Pilot',
        nameFr: 'Swiss MX Sidecar CS Pilot',
        shortNameDe: 'Sidecar CS Pilot',
        shortNameEn: 'Sidecar CS Pilot',
        shortNameFr: 'Sidecar CS Pilot',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Sidecar',
        startListName: 'Sidecar',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 420, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'ab 14 Jahre (Jahrgänge 2009 und älter)',
        hintEn: 'from 14 years (born 2009 and older)',
        hintFr: 'à partir de 14 ans (nés en 2009 et plus)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 50,
        priceDayLicenseWithoutMemberTwoDays: 100,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'Swiss MX Sidecar CS Pilot',
        nameEn: 'Swiss MX Sidecar CS Pilot',
        nameFr: 'Swiss MX Sidecar CS Pilot',
        shortNameDe: 'Sidecar CS Pilot',
        shortNameEn: 'Sidecar CS Pilot',
        shortNameFr: 'Sidecar CS Pilot',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Sidecar',
        startListName: 'Sidecar',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 250, licenseType: 'national' },
          { association: 'fms', value: 550, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 90,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2009 und älter',
        hintEn: 'Born in 2009 and older',
        hintFr: 'Année de naissance 2009 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 40,
        priceDayLicenseWithoutMember: 50,
        priceDayLicenseWithoutMemberTwoDays: 50,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2025-motocross-sidecar-passenger',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: true,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Sidecar CS Passenger',
    commonNameEn: 'Sidecar CS Passenger',
    commonNameFr: 'Sidecar CS Passenger',
    importName: 'Swiss MX Sidecar CS Passenger',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Sidecar',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1051,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss MX Sidecar CS Passenger',
        nameEn: 'Swiss MX Sidecar CS Passenger',
        nameFr: 'Swiss MX Sidecar CS Passenger',
        shortNameDe: 'Sidecar CS Passenger',
        shortNameEn: 'Sidecar CS Passenger',
        shortNameFr: 'Sidecar CS Passenger',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Nur zur Kontrolle, NICHT importieren - Seitenwagen Passagier',
        startListName: 'Sidecar',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 320, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'ab 14 Jahre (Jahrgänge 2009 und älter)',
        hintEn: 'from 14 years (born 2009 and older)',
        hintFr: 'à partir de 14 ans (nés en 2009 et plus)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 50,
        priceDayLicenseWithoutMemberTwoDays: 50,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'Swiss MX Sidecar CS Passenger',
        nameEn: 'Swiss MX Sidecar CS Passenger',
        nameFr: 'Swiss MX Sidecar CS Passenger',
        shortNameDe: 'Sidecar CS Passenger',
        shortNameEn: 'Sidecar CS Passenger',
        shortNameFr: 'Sidecar CS Passenger',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Nur zur Kontrolle, NICHT importieren - Seitenwagen Passagier',
        startListName: 'Sidecar',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 150, licenseType: 'national' },
          { association: 'fms', value: 450, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2009 und älter',
        hintEn: 'Born in 2009 and older',
        hintFr: 'Année de naissance 2009 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 40,
        priceDayLicenseWithoutMember: 50,
        priceDayLicenseWithoutMemberTwoDays: 50,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-motocross-quad',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Quad',
    commonNameEn: 'Quad',
    commonNameFr: 'Quad',
    importName: 'MX Quad',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Quad',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1052,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Quad',
        nameEn: 'Quad',
        nameFr: 'Quad',
        shortNameDe: 'Quad',
        shortNameEn: 'Quad',
        shortNameFr: 'Quad',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Quad',
        startListName: 'Quad',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 420, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'ab 14 Jahre (Jahrgänge 2009 und älter)',
        hintEn: 'from 14 years (born 2009 and older)',
        hintFr: 'à partir de 14 ans (nés en 2009 et plus)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-motocross-mx-e-kids',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'MX E Kids',
    commonNameEn: 'MX E Kids',
    commonNameFr: 'MX E Kids',
    importName: 'MX E Kids',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'MX E Kids',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1053,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'MX E Kids',
        nameEn: 'MX E Kids',
        nameFr: 'MX E Kids',
        shortNameDe: 'MX E Kids',
        shortNameEn: 'MX E Kids',
        shortNameFr: 'MX E Kids',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MX E Kids',
        startListName: 'MX E Kids',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 50, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 50,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Bis 10 Jahre (Jahrgang 2014 und jünger)',
        hintEn: 'Up to 10 years (born 2014 and younger)',
        hintFr: "Jusqu'à 10 ans (nés en 2014 et moins)",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 20,
        priceDayLicenseForMemberTwoDays: 40,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-motocross-mx-kids-65',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'MX Kids 65ccm',
    commonNameEn: 'MX Kids 65ccm',
    commonNameFr: 'MX Kids 65ccm',
    importName: 'MX Kids 65',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'MX Kids 65',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1280,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'MX Kids 65ccm',
        nameEn: 'MX Kids 65ccm',
        nameFr: 'MX Kids 65ccm',
        shortNameDe: 'MX Kids 65ccm',
        shortNameEn: 'MX Kids 65ccm',
        shortNameFr: 'MX Kids 65ccm',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MX Kids 65',
        startListName: 'MX Kids 65',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 50, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 50,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Bis 12 Jahre (Jahrgang 2013 und jünger)',
        hintEn: 'Up to 12 years (born 2013 and younger)',
        hintFr: "Jusqu'à 12 ans (nés en 2013 et moins)",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 20,
        priceDayLicenseForMemberTwoDays: 40,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-motocross-imba-mx-2',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'IMBA MX2',
    commonNameEn: 'IMBA MX2',
    commonNameFr: 'IMBA MX2',
    importName: 'MX IMBA MX2',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'IMBA MX2',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1054,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'IMBA MX2',
        nameEn: 'IMBA MX2',
        nameFr: 'IMBA MX2',
        shortNameDe: 'IMBA MX2',
        shortNameEn: 'IMBA MX2',
        shortNameFr: 'IMBA MX2',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'IMBA MX2',
        startListName: 'IMBA MX2',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 100, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Zusätzlich zu Masters Open',
        hintEn: 'In addition to Masters Open',
        hintFr: 'En plus des Masters Open',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-motocross-imba-open-class',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'IMBA Open Class',
    commonNameEn: 'IMBA Open Class',
    commonNameFr: 'IMBA Open Class',
    importName: 'MX IMBA Open Class',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'IMBA Open',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1055,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'IMBA Open Class',
        nameEn: 'IMBA Open Class',
        nameFr: 'IMBA Open Class',
        shortNameDe: 'IMBA Open Class',
        shortNameEn: 'IMBA Open Class',
        shortNameFr: 'IMBA Open Class',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'IMBA Open',
        startListName: 'IMBA Open',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 100, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Zusätzlich zu Masters Open',
        hintEn: 'In addition to Masters Open',
        hintFr: 'En plus des Masters Open',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-motocross-imba-ladies',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'IMBA Ladies',
    commonNameEn: 'IMBA Ladies',
    commonNameFr: 'IMBA Ladies',
    importName: 'MX IMBA Ladies',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'IMBA Ladies',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1056,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'IMBA Ladies',
        nameEn: 'IMBA Ladies',
        nameFr: 'IMBA Ladies',
        shortNameDe: 'IMBA Ladies',
        shortNameEn: 'IMBA Ladies',
        shortNameFr: 'IMBA Ladies',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'IMBA Ladies',
        startListName: 'IMBA Ladies',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 100, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Zusätzlich zu MX Ladys Open',
        hintEn: 'In addition to MX Ladys Open',
        hintFr: 'En plus de la MX Ladys Open',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-motocross-imba-side-car',
    active: true,
    archived: false,
    sidecar: true,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'IMBA Side Car',
    commonNameEn: 'IMBA Side Car',
    commonNameFr: 'IMBA Side Car',
    importName: 'MX IMBA Side Car',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'IMBA Sidecar',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1057,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'IMBA Side Car',
        nameEn: 'IMBA Side Car',
        nameFr: 'IMBA Side Car',
        shortNameDe: 'IMBA Side Car',
        shortNameEn: 'IMBA Side Car',
        shortNameFr: 'IMBA Side Car',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'IMBA Sidecar',
        startListName: 'IMBA Sidecar',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 100, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Zusätzlich zu Seitenwagen',
        hintEn: 'In addition to sidecar',
        hintFr: 'En plus de Sidecar',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-mofacross-m-0',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'mofacross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Mofacross M0 (bis 50 ccm)',
    commonNameEn: 'Mofacross M0 (bis 50 ccm)',
    commonNameFr: 'Mofacross M0 (bis 50 ccm)',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Mofacross M0',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1058,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Mofacross M0 (bis 50 ccm)',
        nameEn: 'Mofacross M0 (bis 50 ccm)',
        nameFr: 'Mofacross M0 (bis 50 ccm)',
        shortNameDe: 'Mofacross M0',
        shortNameEn: 'Mofacross M0',
        shortNameFr: 'Mofacross M0',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Mofacross M0',
        startListName: 'Mofacross M0',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffffff',
        colorForeground: '#000000',
        colorLicense: '#449eda',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 200, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 65,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'ab 14 Jahre (Jahrgänge 2009 und älter)',
        hintEn: 'from 14 years (born 2009 and older)',
        hintFr: 'à partir de 14 ans (nés en 2009 et plus)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-mofacross-m-1',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'mofacross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Mofacross M1 (bis 75 ccm)',
    commonNameEn: 'Mofacross M1 (bis 75 ccm)',
    commonNameFr: 'Mofacross M1 (bis 75 ccm)',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Mofacross M1',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1059,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Mofacross M1 (bis 75 ccm)',
        nameEn: 'Mofacross M1 (bis 75 ccm)',
        nameFr: 'Mofacross M1 (bis 75 ccm)',
        shortNameDe: 'Mofacross M1',
        shortNameEn: 'Mofacross M1',
        shortNameFr: 'Mofacross M1',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Mofacross M1',
        startListName: 'Mofacross M1',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#449eda',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 200, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 65,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'ab 14 Jahre (Jahrgänge 2009 und älter)',
        hintEn: 'from 14 years (born 2009 and older)',
        hintFr: 'à partir de 14 ans (nés en 2009 et plus)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-mofacross-m-2',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'mofacross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Mofacross M2 (bis 80 ccm)',
    commonNameEn: 'Mofacross M2 (bis 80 ccm)',
    commonNameFr: 'Mofacross M2 (bis 80 ccm)',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Mofacross M2',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1060,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Mofacross M2 (bis 80 ccm)',
        nameEn: 'Mofacross M2 (bis 80 ccm)',
        nameFr: 'Mofacross M2 (bis 80 ccm)',
        shortNameDe: 'Mofacross M2',
        shortNameEn: 'Mofacross M2',
        shortNameFr: 'Mofacross M2',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Mofacross M2',
        startListName: 'Mofacross M2',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ff9900',
        colorForeground: '#000000',
        colorLicense: '#449eda',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 200, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 65,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'ab 14 Jahre (Jahrgänge 2009 und älter)',
        hintEn: 'from 14 years (born 2009 and older)',
        hintFr: 'à partir de 14 ans (nés en 2009 et plus)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-mofacross-m-3',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'mofacross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Mofacross M3 (bis 100 ccm)',
    commonNameEn: 'Mofacross M3 (bis 100 ccm)',
    commonNameFr: 'Mofacross M3 (bis 100 ccm)',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Mofacross M3',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1061,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Mofacross M3 (bis 100 ccm)',
        nameEn: 'Mofacross M3 (bis 100 ccm)',
        nameFr: 'Mofacross M3 (bis 100 ccm)',
        shortNameDe: 'Mofacross M3',
        shortNameEn: 'Mofacross M3',
        shortNameFr: 'Mofacross M3',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Mofacross M3',
        startListName: 'Mofacross M3',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ff9900',
        colorForeground: '#000000',
        colorLicense: '#449eda',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 200, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 65,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'ab 14 Jahre (Jahrgänge 2009 und älter)',
        hintEn: 'from 14 years (born 2009 and older)',
        hintFr: 'à partir de 14 ans (nés en 2009 et plus)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-pitbikecross-pitbike-light-125',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'pitbikecross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Pitbike Light 125',
    commonNameEn: 'Pitbike Light 125',
    commonNameFr: 'Pitbike Light 125',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Pitbike Light 125',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1062,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Pitbike Light 125',
        nameEn: 'Pitbike Light 125',
        nameFr: 'Pitbike Light 125',
        shortNameDe: 'Pitbike Light 125',
        shortNameEn: 'Pitbike Light 125',
        shortNameFr: 'Pitbike Light 125',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Pitbike Light 125',
        startListName: 'Pitbike Light 125',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#3300cc',
        colorForeground: '#ffffff',
        colorLicense: '#801434',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 200, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 65,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-pitbikecross-pitbike-open-200',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'pitbikecross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Pitbike Open 200',
    commonNameEn: 'Pitbike Open 200',
    commonNameFr: 'Pitbike Open 200',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Pitbike Open 200',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1063,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Pitbike Open 200',
        nameEn: 'Pitbike Open 200',
        nameFr: 'Pitbike Open 200',
        shortNameDe: 'Pitbike Open 200',
        shortNameEn: 'Pitbike Open 200',
        shortNameFr: 'Pitbike Open 200',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Pitbike Open 200',
        startListName: 'Pitbike Open 200',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#66ff00',
        colorForeground: '#000000',
        colorLicense: '#801434',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 200, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 65,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'ab 14 Jahre (Jahrgänge 2009 und älter)',
        hintEn: 'from 14 years (born 2009 and older)',
        hintFr: 'à partir de 14 ans (nés en 2009 et plus)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-e-moto-e-moto-1',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'eMoto',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'eMoto 1',
    commonNameEn: 'eMoto 1',
    commonNameFr: 'eMoto 1',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'eMoto Urban Cup',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1064,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'eMoto 1',
        nameEn: 'eMoto 1',
        nameFr: 'eMoto 1',
        shortNameDe: 'eMoto 1',
        shortNameEn: 'eMoto 1',
        shortNameFr: 'eMoto 1',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'eMoto Urban Cup',
        startListName: 'eMoto Urban Cup',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffffff',
        colorForeground: '#000000',
        colorLicense: '#ffeb43',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 200, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 65,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe:
          'Zugelassene Fahrzeuge: Sur-Ron Lightbee, Sur-Ron Lightbee X,  Sur-Ron Ultrabee, KTM Freeride E, Vmoto-R, Caofen, Talaria Sting (weitere gemäss Absprache)',
        hintEn:
          'Approved vehicles: Sur-Ron Lightbee, Sur-Ron Lightbee X, Sur-Ron Ultrabee, KTM Freeride E, Vmoto-R, Caofen, Talaria Sting (others by arrangement)',
        hintFr:
          'Véhicules autorisés : Sur-Ron Lightbee, Sur-Ron Lightbee X, Sur-Ron Ultrabee, KTM Freeride E, Vmoto-R, Caofen, Talaria Sting (autres selon accord)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2025-supermoto-kids',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Kids',
    commonNameEn: 'Kids',
    commonNameFr: 'Kids',
    importName: 'SM Kids',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'KIDS 50/65',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1258,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Kids 50/65',
        nameEn: 'Kids 50/65',
        nameFr: 'Kids 50/65',
        shortNameDe: 'Kids 50/65',
        shortNameEn: 'Kids 50/65',
        shortNameFr: 'Kids 50/65',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'KIDS 50/65',
        startListName: 'KIDS 50/65',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 130, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 50,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2013 und jünger',
        hintEn: 'Born in 2013 and younger',
        hintFr: 'Année de naissance 2013 et moins vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 20,
        priceDayLicenseForMemberTwoDays: 40,
        priceDayLicenseWithoutMember: 20,
        priceDayLicenseWithoutMemberTwoDays: 40,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'Kids 50/65',
        nameEn: 'Kids 50/65',
        nameFr: 'Kids 50/65',
        shortNameDe: 'Kids 50/65',
        shortNameEn: 'Kids 50/65',
        shortNameFr: 'Kids 50/65',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'KIDS 50/65',
        startListName: 'KIDS 50/65',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 90, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 90,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 50,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2013 und jünger',
        hintEn: 'Born in 2013 and younger',
        hintFr: 'Année de naissance 2013 et moins vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 20,
        priceDayLicenseForMemberTwoDays: 20,
        priceDayLicenseWithoutMember: 20,
        priceDayLicenseWithoutMemberTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2025-supermoto-youngster-85',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Youngster 85',
    commonNameEn: 'Youngster 85',
    commonNameFr: 'Youngster 85',
    importName: 'SM Youngster 85',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'YOUNGSTER 85',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1268,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Youngster 85',
        nameEn: 'Youngster 85',
        nameFr: 'Youngster 85',
        shortNameDe: 'Youngster 85',
        shortNameEn: 'Youngster 85',
        shortNameFr: 'Youngster 85',
        needsHealthCheck: [
          { association: 'sam', value: false, licenseType: 'national' },
          { association: 'sam', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'YOUNGSTER 85',
        startListName: 'YOUNGSTER 85',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#3300cc',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 210, licenseType: 'national' },
          { association: 'sam', value: 500, licenseType: 'international' },
        ],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2010 bis 2013',
        hintEn: 'Born between 2010 and 2013',
        hintFr: 'Né entre 2010 et 2013',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 50,
        priceDayLicenseWithoutMemberTwoDays: 100,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'Youngster 85',
        nameEn: 'Youngster 85',
        nameFr: 'Youngster 85',
        shortNameDe: 'Youngster 85',
        shortNameEn: 'Youngster 85',
        shortNameFr: 'Youngster 85',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'YOUNGSTER 85',
        startListName: 'YOUNGSTER 85',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#3300cc',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 110, licenseType: 'national' },
          { association: 'fms', value: 400, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2013 bis 2010',
        hintEn: 'Born between 2013 and 2010',
        hintFr: 'Né entre 2013 et 2010',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 50,
        priceDayLicenseWithoutMemberTwoDays: 50,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2025-supermoto-women',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Women',
    commonNameEn: 'Women',
    commonNameFr: 'Women',
    importName: 'SM Women',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'WOMEN',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1269,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Women',
        nameEn: 'Women',
        nameFr: 'Women',
        shortNameDe: 'Women',
        shortNameEn: 'Women',
        shortNameFr: 'Women',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'WOMEN',
        startListName: 'WOMEN',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#ff66ff',
        colorForeground: '#000000',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 320, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 100,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe:
          'Jahrgang 2010 und älter. Werden bei den Elektro und bei den Women nicht mindestens 8 Lizenzen verkauft, starten diese Kategorien mit den Einsteigern und werden separat gewertet.',
        hintEn:
          'Born in 2010 and older. If there are not at least 8 licenses for the electro and the women, these categories start with the beginners and are evaluated separately.',
        hintFr:
          "Année de naissance 2010 et plus vieux. S'il n'y a pas d'au moins 8 licences pour l'électro et les femmes, ces catégories commencent avec les débutants et sont évaluées séparément.",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'Women',
        nameEn: 'Women',
        nameFr: 'Women',
        shortNameDe: 'Women',
        shortNameEn: 'Women',
        shortNameFr: 'Women',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'WOMEN',
        startListName: 'WOMEN',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#ff66ff',
        colorForeground: '#000000',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 220, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 100,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2010 und älter',
        hintEn: 'Born in 2010 and older',
        hintFr: 'Année de naissance 2010 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 70,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2025-supermoto-elektro',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Elektro',
    commonNameEn: 'Elektro',
    commonNameFr: 'Elektro',
    importName: 'SM Elektro',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'ELEKTRO',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1270,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Elektro',
        nameEn: 'Elektro',
        nameFr: 'Elektro',
        shortNameDe: 'Elektro',
        shortNameEn: 'Elektro',
        shortNameFr: 'Elektro',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'ELEKTRO',
        startListName: 'ELEKTRO',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 320, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 100,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe:
          'Jahrgang 2010 und älter. Werden bei den Elektro und bei den Women nicht mindestens 8 Lizenzen verkauft, starten diese Kategorien mit den Einsteigern und werden separat gewertet.',
        hintEn:
          'Born in 2010 and older. If there are not at least 8 licenses for the electro and the women, these categories start with the beginners and are evaluated separately.',
        hintFr:
          "Année de naissance 2010 et plus vieux. S'il n'y a pas d'au moins 8 licences pour l'électro et les femmes, ces catégories commencent avec les débutants et sont évaluées séparément.",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'Elektro',
        nameEn: 'Elektro',
        nameFr: 'Elektro',
        shortNameDe: 'Elektro',
        shortNameEn: 'Elektro',
        shortNameFr: 'Elektro',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'ELEKTRO',
        startListName: 'ELEKTRO',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 220, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 100,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe:
          'Jahrgang 2010 und älter. Werden bei den Elektro und bei den Women nicht mindestens 10 Lizenzen verkauft, starten diese Kategorien mit den S5 und werden separat gewertet.',
        hintEn:
          'Born in 2010 and older. If there are not at least 10 licenses for the electro and the women, these categories start with the S5 and are ranked separately.',
        hintFr:
          "Année de naissance 2010 et plus vieux. S'il n'y a pas d'au moins 10 licences pour l'électro et les femmes, ces catégories commencent avec les S5 et sont évaluées séparément.",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 70,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2025-supermoto-s-5',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'S5 - Newcomer',
    commonNameEn: 'S5 - Newcomer',
    commonNameFr: 'S5 - Newcomer',
    importName: 'SM S5',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'S5 - NEWCOMER',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1259,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'S5 - Newcomer',
        nameEn: 'S5 - newcomers',
        nameFr: 'S5 - newcomers',
        shortNameDe: 'S5 - Newcomer',
        shortNameEn: 'S5 - newcomers',
        shortNameFr: 'S5 - newcomers',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'S5 - NEWCOMER',
        startListName: 'S5 - NEWCOMER',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#009900',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 320, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 100,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2010 und älter',
        hintEn: 'Born in 2010 and older',
        hintFr: 'Année de naissance 2010 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'S5 - Newcomer',
        nameEn: 'S5 - Newcomer',
        nameFr: 'S5 - Newcomer',
        shortNameDe: 'S5 - Newcomer',
        shortNameEn: 'S5 - Newcomer',
        shortNameFr: 'S5 - Newcomer',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'S5 - NEWCOMER',
        startListName: 'S5 - NEWCOMER',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#009900',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 220, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 100,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2010 und älter',
        hintEn: 'Born in 2010 and older',
        hintFr: 'Année de naissance 2010 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 70,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2025-supermoto-senior',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Senior',
    commonNameEn: 'Senior',
    commonNameFr: 'Senior',
    importName: 'SM Senior',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'SENIOR',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1260,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Senior',
        nameEn: 'Senior',
        nameFr: 'Senior',
        shortNameDe: 'Senior',
        shortNameEn: 'Senior',
        shortNameFr: 'Senior',
        needsHealthCheck: [
          { association: 'sam', value: false, licenseType: 'national' },
          { association: 'sam', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'SENIOR',
        startListName: 'SENIOR',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#3300cc',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 450, licenseType: 'national' },
          { association: 'sam', value: 550, licenseType: 'international' },
        ],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 1990 und älter',
        hintEn: 'Born in 1990 and older',
        hintFr: 'Année de naissance 1990 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'Senior',
        nameEn: 'Senior',
        nameFr: 'Senior',
        shortNameDe: 'Senior',
        shortNameEn: 'Senior',
        shortNameFr: 'Senior',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'SENIOR',
        startListName: 'SENIOR',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#3300cc',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 350, licenseType: 'national' },
          { association: 'fms', value: 450, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 1990 und älter',
        hintEn: 'Born in 1990 and older',
        hintFr: 'Année de naissance 1990 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 70,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2025-supermoto-s-4',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'S4 - Promo',
    commonNameEn: 'S4 - Promo',
    commonNameFr: 'S4 - Promo',
    importName: 'SM S4',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'S4 - PROMO',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1261,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'S4 - Promo',
        nameEn: 'S4 - Promo',
        nameFr: 'S4 - Promo',
        shortNameDe: 'S4 - Promo',
        shortNameEn: 'S4 - Promo',
        shortNameFr: 'S4 - Promo',
        needsHealthCheck: [
          { association: 'sam', value: false, licenseType: 'national' },
          { association: 'sam', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'S4 - PROMO',
        startListName: 'S4 - PROMO',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 450, licenseType: 'national' },
          { association: 'sam', value: 550, licenseType: 'international' },
        ],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2010 und älter',
        hintEn: 'Born in 2010 and older',
        hintFr: 'Année de naissance 2010 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'S4 - Promo',
        nameEn: 'S4 - Promo',
        nameFr: 'S4 - Promo',
        shortNameDe: 'S4 - Promo',
        shortNameEn: 'S4 - Promo',
        shortNameFr: 'S4 - Promo',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'S4 - PROMO',
        startListName: 'S4 - PROMO',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 350, licenseType: 'national' },
          { association: 'fms', value: 450, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2010 und älter',
        hintEn: 'Born in 2010 and older',
        hintFr: 'Année de naissance 2010 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 70,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2025-supermoto-s-2',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'S2 - Challenge',
    commonNameEn: 'S2 - Challenge',
    commonNameFr: 'S2 - Challenge',
    importName: 'SM S2',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'S2 - CHALLENGE',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1262,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'S2 - Challenge',
        nameEn: 'S2 - Challenge',
        nameFr: 'S2 - Challenge',
        shortNameDe: 'S2 - Challenge',
        shortNameEn: 'S2 - Challenge',
        shortNameFr: 'S2 - Challenge',
        needsHealthCheck: [
          { association: 'sam', value: false, licenseType: 'national' },
          { association: 'sam', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'S2 - CHALLENGE',
        startListName: 'S2 - CHALLENGE',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#000000',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 450, licenseType: 'national' },
          { association: 'sam', value: 550, licenseType: 'international' },
        ],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2010 und älter',
        hintEn: 'Born in 2010 and older',
        hintFr: 'Année de naissance 2010 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'S2 - Challenge',
        nameEn: 'S2 - Challenge',
        nameFr: 'S2 - Challenge',
        shortNameDe: 'S2 - Challenge',
        shortNameEn: 'S2 - Challenge',
        shortNameFr: 'S2 - Challenge',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'S2 - CHALLENGE',
        startListName: 'S2 - CHALLENGE',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#000000',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 350, licenseType: 'national' },
          { association: 'fms', value: 450, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2010 und älter',
        hintEn: 'Born in 2010 and older',
        hintFr: 'Année de naissance 2010 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 70,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2025-supermoto-s-1',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'S1 - Prestige',
    commonNameEn: 'S1 - Prestige',
    commonNameFr: 'S1 - Prestige',
    importName: 'SM S1',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'S1 - PRESTIGE',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1263,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'S1 - Prestige',
        nameEn: 'S1 - Prestige',
        nameFr: 'S1 - Prestige',
        shortNameDe: 'S1 - Prestige',
        shortNameEn: 'S1 - Prestige',
        shortNameFr: 'S1 - Prestige',
        needsHealthCheck: [
          { association: 'sam', value: false, licenseType: 'national' },
          { association: 'sam', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'S1 - PRESTIGE',
        startListName: 'S1 - PRESTIGE',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 450, licenseType: 'national' },
          { association: 'sam', value: 550, licenseType: 'international' },
        ],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2010 und älter',
        hintEn: 'Born in 2010 and older',
        hintFr: 'Année de naissance 2010 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'S1 - Prestige',
        nameEn: 'S1 - Prestige',
        nameFr: 'S1 - Prestige',
        shortNameDe: 'S1 - Prestige',
        shortNameEn: 'S1 - Prestige',
        shortNameFr: 'S1 - Prestige',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'S1 - PRESTIGE',
        startListName: 'S1 - PRESTIGE',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 350, licenseType: 'national' },
          { association: 'fms', value: 450, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2010 und älter',
        hintEn: 'Born in 2010 and older',
        hintFr: 'Année de naissance 2010 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 70,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-supermoto-quad',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Quad',
    commonNameEn: 'Quad',
    commonNameFr: 'Quad',
    importName: 'SM Quad',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'QUAD',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1271,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Quad (SAM)',
        nameEn: 'Quad (SAM)',
        nameFr: 'Quad (SAM)',
        shortNameDe: 'Quad (SAM)',
        shortNameEn: 'Quad (SAM)',
        shortNameFr: 'Quad (SAM)',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'QUAD',
        startListName: 'QUAD',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 320, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2010 und älter',
        hintEn: 'Born in 2010 and older',
        hintFr: 'Année de naissance 2010 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-minibike-pitbike-kids-cup-160-ccm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'minibike',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Pitbike Kids-Cup 160 ccm',
    commonNameEn: 'Pitbike Kids-Cup 160 ccm',
    commonNameFr: 'Pitbike Kids-Cup 160 ccm',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'PITBIKE KIDS-CUP',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1065,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Pitbike Kids-Cup 160 ccm',
        nameEn: 'Pitbike Kids-Cup 160 ccm',
        nameFr: 'Pitbike Kids-Cup 160 ccm',
        shortNameDe: 'Pitbike Kids-Cup 160 ccm',
        shortNameEn: 'Pitbike Kids-Cup 160 ccm',
        shortNameFr: 'Pitbike Kids-Cup 160 ccm',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'PITBIKE KIDS-CUP',
        startListName: 'PITBIKE KIDS-CUP',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#ffffff',
        colorForeground: '#000000',
        colorLicense: '#e40716',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 180, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 50,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 20,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'bis 14 Jahre (Jahrgang 2011 und jünger)',
        hintEn: 'up to 14 years (born 2011 and younger)',
        hintFr: "jusqu'à 14 ans (nés en 2011 et moins)",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-minibike-pitbike-mini-gp-160-ccm-series',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'minibike',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Pitbike / MiniGP 160 ccm Series',
    commonNameEn: 'Pitbike / MiniGP 160 ccm Series',
    commonNameFr: 'Pitbike / MiniGP 160 ccm Series',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'PITBIKE / MINIGP 160',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1066,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Pitbike / MiniGP 160 ccm Series',
        nameEn: 'Pitbike / MiniGP 160 ccm Series',
        nameFr: 'Pitbike / MiniGP 160 ccm Series',
        shortNameDe: 'Pitbike / MiniGP 160 ccm Series',
        shortNameEn: 'Pitbike / MiniGP 160 ccm Series',
        shortNameFr: 'Pitbike / MiniGP 160 ccm Series',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'PITBIKE / MINIGP 160',
        startListName: 'PITBIKE / MINIGP 160',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#e40716',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 200, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 20,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-minibike-pitbike-mini-gp-190-ccm-series',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'minibike',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Pitbike / MiniGP 190 ccm Series',
    commonNameEn: 'Pitbike / MiniGP 190 ccm Series',
    commonNameFr: 'Pitbike / MiniGP 190 ccm Series',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'PITBIKE / MINIGP 190',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1067,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Pitbike / MiniGP 190 ccm Series',
        nameEn: 'Pitbike / MiniGP 190 ccm Series',
        nameFr: 'Pitbike / MiniGP 190 ccm Series',
        shortNameDe: 'Pitbike / MiniGP 190 ccm Series',
        shortNameEn: 'Pitbike / MiniGP 190 ccm Series',
        shortNameFr: 'Pitbike / MiniGP 190 ccm Series',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'PITBIKE / MINIGP 190',
        startListName: 'PITBIKE / MINIGP 190',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#ff0000',
        colorForeground: '#ffffff',
        colorLicense: '#e40716',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 200, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 20,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'ab 12 Jahre (Jahrgänge 2013 und älter)',
        hintEn: 'from 12 years (born 2013 and older)',
        hintFr: 'à partir de 12 ans (nés en 2013 et plus)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-minibike-pitbike-mini-gp-open',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'minibike',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Pitbike / MiniGP Open',
    commonNameEn: 'Pitbike / MiniGP Open',
    commonNameFr: 'Pitbike / MiniGP Open',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'PITBIKE / MINIGP OPEN',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1068,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Pitbike / MiniGP Open',
        nameEn: 'Pitbike / MiniGP Open',
        nameFr: 'Pitbike / MiniGP Open',
        shortNameDe: 'Pitbike / MiniGP Open',
        shortNameEn: 'Pitbike / MiniGP Open',
        shortNameFr: 'Pitbike / MiniGP Open',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'PITBIKE / MINIGP OPEN',
        startListName: 'PITBIKE / MINIGP OPEN',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#3300cc',
        colorForeground: '#ffffff',
        colorLicense: '#e40716',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 200, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 20,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'ab 12 Jahre (Jahrgänge 2013 und älter)',
        hintEn: 'from 12 years (born 2013 and older)',
        hintFr: 'à partir de 12 ans (nés en 2013 et plus)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-minibike-pitbike-mini-gp-ladies',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'minibike',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Pitbike / MiniGP Ladies',
    commonNameEn: 'Pitbike / MiniGP Ladies',
    commonNameFr: 'Pitbike / MiniGP Ladies',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'PITBIKE / MINIGP LADIES',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1069,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Pitbike / MiniGP Ladies',
        nameEn: 'Pitbike / MiniGP Ladies',
        nameFr: 'Pitbike / MiniGP Ladies',
        shortNameDe: 'Pitbike / MiniGP Ladies',
        shortNameEn: 'Pitbike / MiniGP Ladies',
        shortNameFr: 'Pitbike / MiniGP Ladies',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'PITBIKE / MINIGP LADIES',
        startListName: 'PITBIKE / MINIGP LADIES',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#ffc0cb',
        colorForeground: '#000000',
        colorLicense: '#e40716',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 200, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 20,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-mofacup-rookies-e-50-cup',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'mofacup',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Rookies E-50 Cup',
    commonNameEn: 'Rookies E-50 Cup',
    commonNameFr: 'Rookies E-50 Cup',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Rookies E-50 Cup',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1070,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Rookies E-50 Cup',
        nameEn: 'Rookies E-50 Cup',
        nameFr: 'Rookies E-50 Cup',
        shortNameDe: 'Rookies E-50 Cup',
        shortNameEn: 'Rookies E-50 Cup',
        shortNameFr: 'Rookies E-50 Cup',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Rookies E-50 Cup',
        startListName: 'Rookies E-50 Cup',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#963d8f',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 200, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 110,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'ab 12 Jahre (Jahrgänge 2013 und älter)',
        hintEn: 'from 12 years (born 2013 and older)',
        hintFr: 'à partir de 12 ans (nés en 2013 et plus)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-mofacup-superstock-70',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'mofacup',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Superstock 70',
    commonNameEn: 'Superstock 70',
    commonNameFr: 'Superstock 70',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Superstock 70',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1071,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Superstock 70',
        nameEn: 'Superstock 70',
        nameFr: 'Superstock 70',
        shortNameDe: 'Superstock 70',
        shortNameEn: 'Superstock 70',
        shortNameFr: 'Superstock 70',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Superstock 70',
        startListName: 'Superstock 70',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#963d8f',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 200, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 120,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'ab 12 Jahre (Jahrgänge 2013 und älter)',
        hintEn: 'from 12 years (born 2013 and older)',
        hintFr: 'à partir de 12 ans (nés en 2013 et plus)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-mofacup-superstock-80',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'mofacup',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Superstock 80',
    commonNameEn: 'Superstock 80',
    commonNameFr: 'Superstock 80',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Superstock 80',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1072,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Superstock 80',
        nameEn: 'Superstock 80',
        nameFr: 'Superstock 80',
        shortNameDe: 'Superstock 80',
        shortNameEn: 'Superstock 80',
        shortNameFr: 'Superstock 80',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Superstock 80',
        startListName: 'Superstock 80',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#963d8f',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 200, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 120,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'ab 12 Jahre (Jahrgänge 2013 und älter)',
        hintEn: 'from 12 years (born 2013 and older)',
        hintFr: 'à partir de 12 ans (nés en 2013 et plus)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-mofacup-open-100',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'mofacup',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Open 100',
    commonNameEn: 'Open 100',
    commonNameFr: 'Open 100',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Open 100',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1073,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Open 100',
        nameEn: 'Open 100',
        nameFr: 'Open 100',
        shortNameDe: 'Open 100',
        shortNameEn: 'Open 100',
        shortNameFr: 'Open 100',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Open 100',
        startListName: 'Open 100',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#963d8f',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 200, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 120,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'ab 12 Jahre (Jahrgänge 2013 und älter)',
        hintEn: 'from 12 years (born 2013 and older)',
        hintFr: 'à partir de 12 ans (nés en 2013 et plus)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-mofacup-puch-cup',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'mofacup',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Puch Cup',
    commonNameEn: 'Puch Cup',
    commonNameFr: 'Puch Cup',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Puch Cup',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1074,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Puch Cup',
        nameEn: 'Puch Cup',
        nameFr: 'Puch Cup',
        shortNameDe: 'Puch Cup',
        shortNameEn: 'Puch Cup',
        shortNameFr: 'Puch Cup',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Puch Cup',
        startListName: 'Puch Cup',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#963d8f',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 200, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 120,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'ab 12 Jahre (Jahrgänge 2013 und älter)',
        hintEn: 'from 12 years (born 2013 and older)',
        hintFr: 'à partir de 12 ans (nés en 2013 et plus)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-mofacup-2-stroke-fun',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'mofacup',
    transponders: [],
    numberChoice: true,
    commonNameDe: '2 Stroke-Fun',
    commonNameEn: '2 Stroke-Fun',
    commonNameFr: '2 Stroke-Fun',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '2 Stroke-Fun',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1277,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: '2 Stroke-Fun',
        nameEn: '2 Stroke-Fun',
        nameFr: '2 Stroke-Fun',
        shortNameDe: '2 Stroke-Fun',
        shortNameEn: '2 Stroke-Fun',
        shortNameFr: '2 Stroke-Fun',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '2 Stroke-Fun',
        startListName: '2 Stroke-Fun',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#963d8f',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 200, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 120,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'ab 12 Jahre (Jahrgänge 2013 und älter)',
        hintEn: 'from 12 years (born 2013 and older)',
        hintFr: 'à partir de 12 ans (nés en 2013 et plus)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-trial-klasse-3',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Klasse 3, Spezialisten, Jg. alle, weisse Spur',
    commonNameEn: 'Klasse 3, Spezialisten, Jg. alle, weisse Spur',
    commonNameFr: 'Klasse 3, Spezialisten, Jg. alle, weisse Spur',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Klasse 3',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1088,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Klasse 3, Spezialisten, Jg. alle, weisse Spur',
        nameEn: 'Klasse 3, Spezialisten, Jg. alle, weisse Spur',
        nameFr: 'Klasse 3, Spezialisten, Jg. alle, weisse Spur',
        shortNameDe: 'Klasse 3',
        shortNameEn: 'Klasse 3',
        shortNameFr: 'Klasse 3',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Klasse 3',
        startListName: 'Klasse 3',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffffff',
        colorForeground: '#000000',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 50, to: '2007-01-01', licenseType: 'national' },
          { association: 'sam', value: 80, from: '2006-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-trial-klasse-4',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Klasse 4, Fortgeschrittene, Jg. 1995 und jünger, blaue Spur',
    commonNameEn: 'Klasse 4, Fortgeschrittene, Jg. 1995 und jünger, blaue Spur',
    commonNameFr: 'Klasse 4, Fortgeschrittene, Jg. 1995 und jünger, blaue Spur',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Klasse 4',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1089,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Klasse 4, Fortgeschrittene, Jg. 1995 und jünger, blaue Spur',
        nameEn: 'Klasse 4, Fortgeschrittene, Jg. 1995 und jünger, blaue Spur',
        nameFr: 'Klasse 4, Fortgeschrittene, Jg. 1995 und jünger, blaue Spur',
        shortNameDe: 'Klasse 4',
        shortNameEn: 'Klasse 4',
        shortNameFr: 'Klasse 4',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Klasse 4',
        startListName: 'Klasse 4',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#3300cc',
        colorForeground: '#ffffff',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 50, to: '2007-01-01', licenseType: 'national' },
          { association: 'sam', value: 80, from: '2006-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'bis 30 Jahre (Jahrgänge 1995 und jünger)',
        hintEn: 'up to 30 years (born 1995 and younger)',
        hintFr: "jusqu'à 30 ans (nés en 1995 et moins)",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-trial-klasse-5',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Klasse 5, Einsteiger, Jg. 1980 und jünger, rote Spur',
    commonNameEn: 'Klasse 5, Einsteiger, Jg. 1980 und jünger, rote Spur',
    commonNameFr: 'Klasse 5, Einsteiger, Jg. 1980 und jünger, rote Spur',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Klasse 5',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1090,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Klasse 5, Einsteiger, Jg. 1980 und jünger, rote Spur',
        nameEn: 'Klasse 5, Einsteiger, Jg. 1980 und jünger, rote Spur',
        nameFr: 'Klasse 5, Einsteiger, Jg. 1980 und jünger, rote Spur',
        shortNameDe: 'Klasse 5',
        shortNameEn: 'Klasse 5',
        shortNameFr: 'Klasse 5',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Klasse 5',
        startListName: 'Klasse 5',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 50, to: '2007-01-01', licenseType: 'national' },
          { association: 'sam', value: 80, from: '2006-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'bis 45 Jahre (Jahrgänge 1980 und jünger',
        hintEn: 'up to 45 years (born 1980 and younger',
        hintFr: "jusqu'à 45 ans (nés en 1980 et moins",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-trial-klasse-6',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Klasse 6, Nachwuchs, Jg. 2007 und jünger, grüne Spur',
    commonNameEn: 'Klasse 6, Nachwuchs, Jg. 2007 und jünger, grüne Spur',
    commonNameFr: 'Klasse 6, Nachwuchs, Jg. 2007 und jünger, grüne Spur',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Klasse 6',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1091,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Klasse 6, Nachwuchs, Jg. 2007 und jünger, grüne Spur',
        nameEn: 'Klasse 6, Nachwuchs, Jg. 2007 und jünger, grüne Spur',
        nameFr: 'Klasse 6, Nachwuchs, Jg. 2007 und jünger, grüne Spur',
        shortNameDe: 'Klasse 6',
        shortNameEn: 'Klasse 6',
        shortNameFr: 'Klasse 6',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Klasse 6',
        startListName: 'Klasse 6',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#009900',
        colorForeground: '#ffffff',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 50, to: '2007-01-01', licenseType: 'national' },
          { association: 'sam', value: 80, from: '2006-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'bis 18 Jahre (Jahrgänge 2007 und jünger)',
        hintEn: 'up to 18 years (born 2007 and younger)',
        hintFr: "jusqu'à 18 ans (nés en 2007 et moins)",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-trial-klasse-7',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Klasse 7, Senioren, Jg. 1994 und älter, blaue Spur',
    commonNameEn: 'Klasse 7, Senioren, Jg. 1994 und älter, blaue Spur',
    commonNameFr: 'Klasse 7, Senioren, Jg. 1994 und älter, blaue Spur',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Klasse 7',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1092,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Klasse 7, Senioren, Jg. 1994 und älter, blaue Spur',
        nameEn: 'Klasse 7, Senioren, Jg. 1994 und älter, blaue Spur',
        nameFr: 'Klasse 7, Senioren, Jg. 1994 und älter, blaue Spur',
        shortNameDe: 'Klasse 7',
        shortNameEn: 'Klasse 7',
        shortNameFr: 'Klasse 7',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Klasse 7',
        startListName: 'Klasse 7',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#3300cc',
        colorForeground: '#ffffff',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 50, to: '2007-01-01', licenseType: 'national' },
          { association: 'sam', value: 80, from: '2006-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'ab 30 Jahre (Jahrgänge 1994 und älter)',
        hintEn: 'from 30 years (born 1994 and older)',
        hintFr: 'à partir de 30 ans (nés en 1994 et plus)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-trial-klasse-8',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Klasse 8, Veteranen, Jg. 1979 und älter, rote Spur',
    commonNameEn: 'Klasse 8, Veteranen, Jg. 1979 und älter, rote Spur',
    commonNameFr: 'Klasse 8, Veteranen, Jg. 1979 und älter, rote Spur',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Klasse 8',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1093,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Klasse 8, Veteranen, Jg. 1979 und älter, rote Spur',
        nameEn: 'Klasse 8, Veteranen, Jg. 1979 und älter, rote Spur',
        nameFr: 'Klasse 8, Veteranen, Jg. 1979 und älter, rote Spur',
        shortNameDe: 'Klasse 8',
        shortNameEn: 'Klasse 8',
        shortNameFr: 'Klasse 8',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Klasse 8',
        startListName: 'Klasse 8',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 50, to: '2007-01-01', licenseType: 'national' },
          { association: 'sam', value: 80, from: '2006-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'ab 45 Jahre (Jahrgänge 1979 und älter)',
        hintEn: 'from 45 years (born 1979 and older)',
        hintFr: 'à partir de 45 ans (nés en 1979 et plus)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-trial-klasse-8-t',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Klasse 8T, Twinshock Expert, Jg. alle, rote Spur',
    commonNameEn: 'Klasse 8T, Twinshock Expert, Jg. alle, rote Spur',
    commonNameFr: 'Klasse 8T, Twinshock Expert, Jg. alle, rote Spur',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Klasse 8T',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1094,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Klasse 8T, Twinshock Expert, Jg. alle, rote Spur',
        nameEn: 'Klasse 8T, Twinshock Expert, Jg. alle, rote Spur',
        nameFr: 'Klasse 8T, Twinshock Expert, Jg. alle, rote Spur',
        shortNameDe: 'Klasse 8T',
        shortNameEn: 'Klasse 8T',
        shortNameFr: 'Klasse 8T',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Klasse 8T',
        startListName: 'Klasse 8T',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 50, to: '2007-01-01', licenseType: 'national' },
          { association: 'sam', value: 80, from: '2006-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-trial-klasse-9',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Klasse 9, Hobby, Jg. 2006 und älter, grüne Spur',
    commonNameEn: 'Klasse 9, Hobby, Jg. 2006 und älter, grüne Spur',
    commonNameFr: 'Klasse 9, Hobby, Jg. 2006 und älter, grüne Spur',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Klasse 9',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1095,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Klasse 9, Hobby, Jg. 2006 und älter, grüne Spur',
        nameEn: 'Klasse 9, Hobby, Jg. 2006 und älter, grüne Spur',
        nameFr: 'Klasse 9, Hobby, Jg. 2006 und älter, grüne Spur',
        shortNameDe: 'Klasse 9',
        shortNameEn: 'Klasse 9',
        shortNameFr: 'Klasse 9',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Klasse 9',
        startListName: 'Klasse 9',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#009900',
        colorForeground: '#ffffff',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 50, to: '2007-01-01', licenseType: 'national' },
          { association: 'sam', value: 80, from: '2006-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'ab 19 Jahre (Jahrgänge 2006 und älter)',
        hintEn: 'from 19 years (born 2006 and older)',
        hintFr: 'à partir de 19 ans (nés en 2006 et plus)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-trial-klasse-9-t',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Klasse 9T, Twinshock Clubman, Jg. alle, grüne Spur',
    commonNameEn: 'Klasse 9T, Twinshock Clubman, Jg. alle, grüne Spur',
    commonNameFr: 'Klasse 9T, Twinshock Clubman, Jg. alle, grüne Spur',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Klasse 9T',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1096,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Klasse 9T, Twinshock Clubman, Jg. alle, grüne Spur',
        nameEn: 'Klasse 9T, Twinshock Clubman, Jg. alle, grüne Spur',
        nameFr: 'Klasse 9T, Twinshock Clubman, Jg. alle, grüne Spur',
        shortNameDe: 'Klasse 9T',
        shortNameEn: 'Klasse 9T',
        shortNameFr: 'Klasse 9T',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Klasse 9T',
        startListName: 'Klasse 9T',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#009900',
        colorForeground: '#ffffff',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 50, to: '2007-01-01', licenseType: 'national' },
          { association: 'sam', value: 80, from: '2006-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-trial-elektro-trial',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Elektro Trial, Elektro Trial, Jg. alle, rote Spur',
    commonNameEn: 'Elektro Trial, Elektro Trial, Jg. alle, rote Spur',
    commonNameFr: 'Elektro Trial, Elektro Trial, Jg. alle, rote Spur',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Elektro Trial',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1097,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Elektro Trial, Elektro Trial, Jg. alle, rote Spur',
        nameEn: 'Elektro Trial, Elektro Trial, Jg. alle, rote Spur',
        nameFr: 'Elektro Trial, Elektro Trial, Jg. alle, rote Spur',
        shortNameDe: 'Elektro Trial',
        shortNameEn: 'Elektro Trial',
        shortNameFr: 'Elektro Trial',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Elektro Trial',
        startListName: 'Elektro Trial',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 50, to: '2007-01-01', licenseType: 'national' },
          { association: 'sam', value: 80, from: '2006-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-trial-kids-klasse-1',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Kids Klasse 1, E Kids Cup 12.5" und 16", Jg. 2021-2023, weisse Spur leicht',
    commonNameEn: 'Kids Klasse 1, E Kids Cup 12.5" und 16", Jg. 2021-2023, weisse Spur leicht',
    commonNameFr: 'Kids Klasse 1, E Kids Cup 12.5" und 16", Jg. 2021-2023, weisse Spur leicht',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Kids Klasse 1',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1098,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Kids Klasse 1, E Kids Cup 12.5" und 16", Jg. 2021-2023, weisse Spur leicht',
        nameEn: 'Kids Klasse 1, E Kids Cup 12.5" und 16", Jg. 2021-2023, weisse Spur leicht',
        nameFr: 'Kids Klasse 1, E Kids Cup 12.5" und 16", Jg. 2021-2023, weisse Spur leicht',
        shortNameDe: 'Kids Klasse 1',
        shortNameEn: 'Kids Klasse 1',
        shortNameFr: 'Kids Klasse 1',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Kids Klasse 1',
        startListName: 'Kids Klasse 1',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 40, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'ab 2.5 Jahre (Jahrgänge 2021-2023)',
        hintEn: 'from 2.5 years (born 2021-2023)',
        hintFr: 'à partir de 2,5 ans (nés en 2021-2023)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-trial-kids-klasse-2',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Kids Klasse 2, E Kids Cup 12.5" und 16", Jg. 2018-2020, weisse Spur leicht',
    commonNameEn: 'Kids Klasse 2, E Kids Cup 12.5" und 16", Jg. 2018-2020, weisse Spur leicht',
    commonNameFr: 'Kids Klasse 2, E Kids Cup 12.5" und 16", Jg. 2018-2020, weisse Spur leicht',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Kids Klasse 2',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1099,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Kids Klasse 2, E Kids Cup 12.5" und 16", Jg. 2018-2020, weisse Spur leicht',
        nameEn: 'Kids Klasse 2, E Kids Cup 12.5" und 16", Jg. 2018-2020, weisse Spur leicht',
        nameFr: 'Kids Klasse 2, E Kids Cup 12.5" und 16", Jg. 2018-2020, weisse Spur leicht',
        shortNameDe: 'Kids Klasse 2',
        shortNameEn: 'Kids Klasse 2',
        shortNameFr: 'Kids Klasse 2',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Kids Klasse 2',
        startListName: 'Kids Klasse 2',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 40, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'ab 5 Jahre (Jahrgänge 2018-2020)',
        hintEn: 'from 5 years (born 2018-2020)',
        hintFr: 'à partir de 5 ans (nés 2018-2020)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-trial-kids-klasse-3',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Kids Klasse 3, E Kids Cup 16" und 20", Jg. 2015-2017, grüne Spur mittel',
    commonNameEn: 'Kids Klasse 3, E Kids Cup 16" und 20", Jg. 2015-2017, grüne Spur mittel',
    commonNameFr: 'Kids Klasse 3, E Kids Cup 16" und 20", Jg. 2015-2017, grüne Spur mittel',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Kids Klasse 3',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1100,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Kids Klasse 3, E Kids Cup 16" und 20", Jg. 2015-2017, grüne Spur mittel',
        nameEn: 'Kids Klasse 3, E Kids Cup 16" und 20", Jg. 2015-2017, grüne Spur mittel',
        nameFr: 'Kids Klasse 3, E Kids Cup 16" und 20", Jg. 2015-2017, grüne Spur mittel',
        shortNameDe: 'Kids Klasse 3',
        shortNameEn: 'Kids Klasse 3',
        shortNameFr: 'Kids Klasse 3',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Kids Klasse 3',
        startListName: 'Kids Klasse 3',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 40, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'ab 8 Jahre (Jahrgänge 2015-2017)',
        hintEn: 'from 8 years (born 2015-2017)',
        hintFr: 'à partir de 8 ans (nés 2015-2017)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-trial-kids-klasse-4',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Kids Klasse 4, E Kids Cup 16" und 20", Jg. 2015-2017, grüne Spur mittel',
    commonNameEn: 'Kids Klasse 4, E Kids Cup 16" und 20", Jg. 2015-2017, grüne Spur mittel',
    commonNameFr: 'Kids Klasse 4, E Kids Cup 16" und 20", Jg. 2015-2017, grüne Spur mittel',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Kids Klasse 4',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1101,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Kids Klasse 4, E Kids Cup 16" und 20", Jg. 2015-2017, grüne Spur mittel',
        nameEn: 'Kids Klasse 4, E Kids Cup 16" und 20", Jg. 2015-2017, grüne Spur mittel',
        nameFr: 'Kids Klasse 4, E Kids Cup 16" und 20", Jg. 2015-2017, grüne Spur mittel',
        shortNameDe: 'Kids Klasse 4',
        shortNameEn: 'Kids Klasse 4',
        shortNameFr: 'Kids Klasse 4',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Kids Klasse 4',
        startListName: 'Kids Klasse 4',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 40, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'ab 8 Jahre (Jahrgänge 2015-2017)',
        hintEn: 'from 8 years (born 2015-2017)',
        hintFr: 'à partir de 8 ans (nés 2015-2017)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-trial-kids-klasse-5',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Kids Klasse 5, E Kids Cup 20", Jg. 2010-2016, gelbe Spur schwer',
    commonNameEn: 'Kids Klasse 5, E Kids Cup 20", Jg. 2010-2016, gelbe Spur schwer',
    commonNameFr: 'Kids Klasse 5, E Kids Cup 20", Jg. 2010-2016, gelbe Spur schwer',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Kids Klasse 5',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1102,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Kids Klasse 5, E Kids Cup 20", Jg. 2010-2016, gelbe Spur schwer',
        nameEn: 'Kids Klasse 5, E Kids Cup 20", Jg. 2010-2016, gelbe Spur schwer',
        nameFr: 'Kids Klasse 5, E Kids Cup 20", Jg. 2010-2016, gelbe Spur schwer',
        shortNameDe: 'Kids Klasse 5',
        shortNameEn: 'Kids Klasse 5',
        shortNameFr: 'Kids Klasse 5',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Kids Klasse 5',
        startListName: 'Kids Klasse 5',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 40, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'ab 9 Jahre (Jahrgänge 2010-2016)',
        hintEn: 'from 9 years (born 2010-2016)',
        hintFr: 'à partir de 9 ans (nés 2010-2016)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-other-trainingslizenz-funlizenz',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'other',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Trainingslizenz / Funlizenz',
    commonNameEn: 'Trainingslizenz / Funlizenz',
    commonNameFr: 'Trainingslizenz / Funlizenz',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Trainingslizenz / Funlizenz',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1075,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Trainingslizenz / Funlizenz',
        nameEn: 'Trainingslizenz / Funlizenz',
        nameFr: 'Trainingslizenz / Funlizenz',
        shortNameDe: 'Fun',
        shortNameEn: 'Fun',
        shortNameFr: 'Fun',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Trainingslizenz / Funlizenz',
        startListName: 'Trainingslizenz / Funlizenz',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#afa88b',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 290, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-other-transponder-dummy',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'other',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Transponder-Dummy',
    commonNameEn: 'Transponder-Dummy',
    commonNameFr: 'Transponder-Dummy',
    importName: '',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: 'Transponder-Dummy',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1076,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Transponder-Dummy',
        nameEn: 'Transponder-Dummy',
        nameFr: 'Transponder-Dummy',
        shortNameDe: 'Transponder-Dummy',
        shortNameEn: 'Transponder-Dummy',
        shortNameFr: 'Transponder-Dummy',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Transponder-Dummy',
        startListName: 'Transponder-Dummy',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#afa88b',
        numberFixed: false,
        association: 'sam',
        prices: [{ association: 'sam', value: 0, licenseType: 'national' }],
        priceHintDe:
          'wird benötigt, wenn ein Transponder gekauft werden soll, ohne eine Lizenz zu lösen',
        priceHintEn: 'is required if a transponder is to be purchased without purchasing a license',
        priceHintFr: 'est requis si un transpondeur doit être acheté sans acheter de licence',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: false,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-omc-omc-twin-shock',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'omc',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'OMC TwinShock',
    commonNameEn: 'OMC TwinShock',
    commonNameFr: 'OMC TwinShock',
    importName: '',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: 'OMC TwinShock',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1272,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'OMC TwinShock',
        nameEn: 'OMC TwinShock',
        nameFr: 'OMC TwinShock',
        shortNameDe: 'OMC TwinShock',
        shortNameEn: 'OMC TwinShock',
        shortNameFr: 'OMC TwinShock',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'OMC TwinShock',
        startListName: 'OMC TwinShock',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 0, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 70,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: false,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-omc-omc-classic-65',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'omc',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'OMC Classic 65+',
    commonNameEn: 'OMC Classic 65+',
    commonNameFr: 'OMC Classic 65+',
    importName: '',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: 'OMC Classic 65+',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1273,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'OMC Classic 65+',
        nameEn: 'OMC Classic 65+',
        nameFr: 'OMC Classic 65+',
        shortNameDe: 'OMC Classic 65+',
        shortNameEn: 'OMC Classic 65+',
        shortNameFr: 'OMC Classic 65+',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'OMC Classic 65+',
        startListName: 'OMC Classic 65+',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 0, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 70,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: false,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-omc-omc-classic',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'omc',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'OMC Classic',
    commonNameEn: 'OMC Classic',
    commonNameFr: 'OMC Classic',
    importName: '',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: 'OMC Classic',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1274,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'OMC Classic',
        nameEn: 'OMC Classic',
        nameFr: 'OMC Classic',
        shortNameDe: 'OMC Classic',
        shortNameEn: 'OMC Classic',
        shortNameFr: 'OMC Classic',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'OMC Classic',
        startListName: 'OMC Classic',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 0, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 70,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: false,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-omc-omc-evo',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'omc',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'OMC EVO',
    commonNameEn: 'OMC EVO',
    commonNameFr: 'OMC EVO',
    importName: '',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: 'OMC EVO',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1275,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'OMC EVO',
        nameEn: 'OMC EVO',
        nameFr: 'OMC EVO',
        shortNameDe: 'OMC EVO',
        shortNameEn: 'OMC EVO',
        shortNameFr: 'OMC EVO',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'OMC EVO',
        startListName: 'OMC EVO',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 0, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 70,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: false,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-enduro-cross-country-enduro-fun',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduroCrossCountry',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Enduro Fun',
    commonNameEn: 'Enduro Fun',
    commonNameFr: 'Enduro Fun',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Enduro Fun',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1077,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Enduro Fun',
        nameEn: 'Enduro Fun',
        nameFr: 'Enduro Fun',
        shortNameDe: 'Enduro Fun',
        shortNameEn: 'Enduro Fun',
        shortNameFr: 'Enduro Fun',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Enduro Fun',
        startListName: 'Enduro Fun',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 320, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 85,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe:
          'Für Fahrer ohne eine andere Lizenz (Swiss Moto, SAM-MX, andere Enduro-Lizenz), nur Enduro-Motorräder',
        hintEn:
          'For riders without another license (Swiss Moto, SAM-MX, other Enduro license), only Enduro motorcycles',
        hintFr:
          'Pour les pilotes sans autre permis (Swiss Moto, SAM-MX, autre permis Enduro), uniquement les motos Enduro',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-enduro-cross-country-enduro-masters',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduroCrossCountry',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Enduro Masters',
    commonNameEn: 'Enduro Masters',
    commonNameFr: 'Enduro Masters',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Enduro Masters',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1078,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Enduro Masters',
        nameEn: 'Enduro Masters',
        nameFr: 'Enduro Masters',
        shortNameDe: 'Enduro Masters',
        shortNameEn: 'Enduro Masters',
        shortNameFr: 'Enduro Masters',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Enduro Masters',
        startListName: 'Enduro Masters',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 320, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 85,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe:
          'Für Fahrer ohne eine andere Lizenz (Swiss Moto, SAM-MX, andere Enduro-Lizenz), nur Enduro-Motorräder',
        hintEn:
          'For riders without another license (Swiss Moto, SAM-MX, other Enduro license), only Enduro motorcycles',
        hintFr:
          'Pour les pilotes sans autre permis (Swiss Moto, SAM-MX, autre permis Enduro), uniquement les motos Enduro',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-enduro-cross-country-enduro-u-50',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduroCrossCountry',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Enduro Ü50',
    commonNameEn: 'Enduro Ü50',
    commonNameFr: 'Enduro Ü50',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Enduro Ü50',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1079,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Enduro Ü50',
        nameEn: 'Enduro Ü50',
        nameFr: 'Enduro Ü50',
        shortNameDe: 'Enduro Ü50',
        shortNameEn: 'Enduro Ü50',
        shortNameFr: 'Enduro Ü50',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Enduro Ü50',
        startListName: 'Enduro Ü50',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 320, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 85,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe:
          'Ab 50 Jahre (Jahrgänge 1975 und älter)\nFür Fahrer ohne eine andere Lizenz (Swiss Moto, SAM-MX, andere Enduro-Lizenz), nur Enduro-Motorräder',
        hintEn:
          'From 50 years (born 1975 and older)\nFor riders without another license (Swiss Moto, SAM-MX, other Enduro license), only Enduro motorcycles',
        hintFr:
          'A partir de 50 ans (nés en 1975 et plus)\nPour les pilotes sans autre permis (Swiss Moto, SAM-MX, autre permis Enduro), uniquement les motos Enduro',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2025-enduro-cross-country-cce-cross',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduroCrossCountry',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'CCE Cross',
    commonNameEn: 'CCE Cross',
    commonNameFr: 'CCE Cross',
    importName: '',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'CCE Cross',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1080,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'CCE Cross',
        nameEn: 'CCE Cross',
        nameFr: 'CCE Cross',
        shortNameDe: 'CCE Cross',
        shortNameEn: 'CCE Cross',
        shortNameFr: 'CCE Cross',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'CCE Cross',
        startListName: 'CCE Cross',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 320, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Includes extended insurance coverage',
        priceHintFr: 'Comprend une couverture d’assurance étendue',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 175,
        priceInscriptionWithLicense: 85,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe:
          'Für Fahrer ohne eine andere Lizenz (Swiss Moto, SAM-MX, andere Enduro-Lizenz), nur Motocross-Motorräder',
        hintEn:
          'For riders without another license (Swiss Moto, SAM-MX, other Enduro license), only motocross motorcycles',
        hintFr:
          'Pour les pilotes sans autre permis (Swiss Moto, SAM-MX, autre permis Enduro), uniquement les motos motocross',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-masters',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Masters',
    commonNameEn: 'Masters',
    commonNameFr: 'Masters',
    importName: 'Swiss Moto MX Masters',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Swiss Moto MX Masters',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1103,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Masters',
        nameEn: 'Masters',
        nameFr: 'Masters',
        shortNameDe: 'Masters',
        shortNameEn: 'Masters',
        shortNameFr: 'Masters',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Swiss Moto MX Masters',
        startListName: 'Swiss Moto MX Masters',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 600, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 90,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2009 und älter',
        hintEn: 'Born in 2009 and older',
        hintFr: 'Année de naissance 2009 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-mx-2',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Swiss Moto MX 2',
    commonNameEn: 'Swiss Moto MX 2',
    commonNameFr: 'Swiss Moto MX 2',
    importName: 'Swiss Moto MX 2',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Swiss Moto MX2',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1104,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss Moto MX 2',
        nameEn: 'Swiss Moto MX 2',
        nameFr: 'Swiss Moto MX 2',
        shortNameDe: 'Swiss Moto MX 2',
        shortNameEn: 'Swiss Moto MX 2',
        shortNameFr: 'Swiss Moto MX 2',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Swiss Moto MX2',
        startListName: 'Swiss Moto MX2',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 600, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 90,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 20010 und älter',
        hintEn: 'Born in 20010 and older',
        hintFr: 'Année de naissance 20010 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-erweiterung-swiss-moto-mx-masters',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Erweiterung Swiss Moto MX Masters',
    commonNameEn: 'Extension Swiss Moto MX Masters',
    commonNameFr: 'Extension Swiss Moto MX Masters',
    importName: 'MX Erweiterung Swiss Moto MX Masters',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: 'Swiss Moto MX Masters',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1105,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Erweiterung Swiss Moto MX Masters',
        nameEn: 'Extension Swiss Moto MX Masters',
        nameFr: 'Extension Swiss Moto MX Masters',
        shortNameDe: 'Erweiterung Swiss Moto MX Masters',
        shortNameEn: 'Extension Swiss Moto MX Masters',
        shortNameFr: 'Extension Swiss Moto MX Masters',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Swiss Moto MX Masters',
        startListName: 'Swiss Moto MX Masters',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 110, licenseType: 'national' }],
        priceHintDe: 'Zusätzlich zu National Open, National 250, Junioren 125, oder Women Cup',
        priceHintEn: 'In addition to National Open, National 250, Junior 125, or Women Cup',
        priceHintFr: 'En plus du National Open, du National 250, du Junior 125 ou de la Women Cup',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 90,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2009 und älter',
        hintEn: 'Born in 2009 and older',
        hintFr: 'Année de naissance 2009 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-erweiterung-swiss-moto-mx-2',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Erweiterung Swiss Moto MX 2',
    commonNameEn: 'Extension Swiss Moto MX 2',
    commonNameFr: 'Extension Swiss Moto MX 2',
    importName: 'MX Erweiterung Swiss Moto MX 2',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: 'Swiss Moto MX2',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1106,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Erweiterung Swiss Moto MX 2',
        nameEn: 'Extension Swiss Moto MX 2',
        nameFr: 'Extension Swiss Moto MX 2',
        shortNameDe: 'Erweiterung Swiss Moto MX 2',
        shortNameEn: 'Extension Swiss Moto MX 2',
        shortNameFr: 'Extension Swiss Moto MX 2',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Swiss Moto MX2',
        startListName: 'Swiss Moto MX2',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 110, licenseType: 'national' }],
        priceHintDe: 'Zusätzlich zu National Open, National 250, Junioren 125, oder Women Cup',
        priceHintEn: 'In addition to National Open, National 250, Junior 125, or Women Cup',
        priceHintFr: 'En plus du National Open, du National 250, du Junior 125 ou de la Women Cup',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 90,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2010 und älter',
        hintEn: 'Born in 2010 and older',
        hintFr: 'Année de naissance 2010 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-swiss-moto-mx-masters-auslandische-lizenz-licence-etrangere',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Swiss Moto MX Masters ausländische Lizenz / licence étrangère',
    commonNameEn: 'Swiss Moto MX Masters licence étrangère',
    commonNameFr: 'Swiss Moto MX Masters foreign license',
    importName: 'MX Swiss Moto MX Masters ausländische Lizenz / licence étrangère',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: 'Swiss Moto MX Masters',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1107,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss Moto MX Masters ausländische Lizenz / licence étrangère',
        nameEn: 'Swiss Moto MX Masters foreign license',
        nameFr: 'Swiss Moto MX Masters licence étrangère',
        shortNameDe: 'Swiss Moto MX Masters ausländische Lizenz / licence étrangère',
        shortNameEn: 'Swiss Moto MX Masters foreign license',
        shortNameFr: 'Swiss Moto MX Masters licence étrangère',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Swiss Moto MX Masters',
        startListName: 'Swiss Moto MX Masters',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 0, licenseType: 'international' }],
        priceHintDe:
          'Nur gültig, wenn eine gültige, ausländische Jahreslizenz inkl. Startgenehmigung eingereicht wird.',
        priceHintEn:
          'Only valid if a valid foreign annual license including starting permit is submitted.',
        priceHintFr:
          "Seulement valable si une valide licence étrangère annuelle, y compris l'autorisation de sortie, est soumise.",
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 90,
        discountInscriptionWithLicenseTwoDays: 20,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2009 und älter',
        hintEn: 'Born in 2009 and older',
        hintFr: 'Année de naissance 2009 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: false,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-swiss-moto-mx-2-auslandische-lizenz-licence-etrangere',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Swiss Moto MX 2 ausländische Lizenz / licence étrangère',
    commonNameEn: 'Swiss Moto MX 2 licence étrangère',
    commonNameFr: 'Swiss Moto MX 2 foreign license',
    importName: 'MX Swiss Moto MX 2 ausländische Lizenz / licence étrangère',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: 'Swiss Moto MX2',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1108,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss Moto MX 2 ausländische Lizenz / licence étrangère',
        nameEn: 'Swiss Moto MX 2 foreign license',
        nameFr: 'Swiss Moto MX 2 licence étrangère',
        shortNameDe: 'Swiss Moto MX 2 ausländische Lizenz / licence étrangère',
        shortNameEn: 'Swiss Moto MX 2 foreign license',
        shortNameFr: 'Swiss Moto MX 2 licence étrangère',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Swiss Moto MX2',
        startListName: 'Swiss Moto MX2',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 0, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 90,
        discountInscriptionWithLicenseTwoDays: 20,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2010 und älter',
        hintEn: 'Born in 2010 and older',
        hintFr: 'Année de naissance 2010 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: false,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-national-open',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Swiss Moto MX National Open',
    commonNameEn: 'Swiss Moto MX National Open',
    commonNameFr: 'Swiss Moto MX National Open',
    importName: 'Swiss Moto MX National Open',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'National Open',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1109,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss Moto MX National Open',
        nameEn: 'Swiss Moto MX National Open',
        nameFr: 'Swiss Moto MX National Open',
        shortNameDe: 'Swiss Moto MX National Open',
        shortNameEn: 'Swiss Moto MX National Open',
        shortNameFr: 'Swiss Moto MX National Open',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'National Open',
        startListName: 'National Open',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 170, to: '2007-01-01', licenseType: 'national' },
          { association: 'fms', value: 380, from: '2006-12-31', licenseType: 'national' },
          { association: 'fms', value: 600, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 90,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2009 und älter',
        hintEn: 'Born in 2009 and older',
        hintFr: 'Année de naissance 2009 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-national-250',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Swiss Moto MX National 250',
    commonNameEn: 'Swiss Moto MX National 250',
    commonNameFr: 'Swiss Moto MX National 250',
    importName: 'Swiss Moto MX National 250',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'National 250',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1110,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss Moto MX National 250',
        nameEn: 'Swiss Moto MX National 250',
        nameFr: 'Swiss Moto MX National 250',
        shortNameDe: 'Swiss Moto MX National 250',
        shortNameEn: 'Swiss Moto MX National 250',
        shortNameFr: 'Swiss Moto MX National 250',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'National 250',
        startListName: 'National 250',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 170, to: '2007-01-01', licenseType: 'national' },
          { association: 'fms', value: 440, from: '2006-12-31', licenseType: 'national' },
          { association: 'fms', value: 600, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 90,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2011 bis 1997',
        hintEn: 'Born between 2011 and 1997',
        hintFr: 'Né entre 2011 et 1997',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-junioren-125',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Swiss Moto MX Junioren 125',
    commonNameEn: 'Swiss Moto MX Juniors 125',
    commonNameFr: 'Swiss Moto MX Juniors 125',
    importName: 'Swiss Moto MX Junioren 125',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Junioren 125',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1111,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss Moto MX Junioren 125',
        nameEn: 'Swiss Moto MX Juniors 125',
        nameFr: 'Swiss Moto MX Juniors 125',
        shortNameDe: 'Swiss Moto MX Junioren 125',
        shortNameEn: 'Swiss Moto MX Juniors 125',
        shortNameFr: 'Swiss Moto MX Juniors 125',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Junioren 125',
        startListName: 'Junioren 125',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 170, licenseType: 'national' },
          { association: 'fms', value: 440, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 90,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2012 bis 2006',
        hintEn: 'Born between 2012 and 2006',
        hintFr: 'Né entre 2012 et 2006',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 70,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-junioren-85',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Swiss Moto MX Backyard Racing Junioren 85',
    commonNameEn: 'Swiss Moto MX Backyard Junioren 85',
    commonNameFr: 'Swiss Moto MX Backyard Racing Junioren 85',
    importName: 'Swiss Moto MX Backyard Racing Junioren 85',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Backyard Racing Junioren 85',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1112,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss Moto MX Backyard Racing Junioren 85',
        nameEn: 'Swiss Moto MX Backyard Racing Junioren 85',
        nameFr: 'Swiss Moto MX Backyard Junioren 85',
        shortNameDe: 'Swiss Moto MX Backyard Racing Junioren 85',
        shortNameEn: 'Swiss Moto MX Backyard Racing Junioren 85',
        shortNameFr: 'Swiss Moto MX Backyard Racing Junioren 85',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Backyard Racing Junioren 85',
        startListName: 'Backyard Racing Junioren 85',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 170, licenseType: 'national' },
          { association: 'fms', value: 440, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 50,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2014 bis 2010',
        hintEn: 'Born between 2014 and 2010',
        hintFr: 'Né entre 2014 et 2010',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 30,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 40,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-kids-cup-65',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Swiss Moto MX Eschtec Kids Cup 65',
    commonNameEn: 'Swiss Moto MX Eschtec Kids Cup 65',
    commonNameFr: 'Swiss Moto MX Eschtec Kids Cup 65',
    importName: 'Swiss Moto MX Eschtec Cup 65',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Eschtec Cup 65',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1113,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss Moto MX Eschtec Kids Cup 65',
        nameEn: 'Swiss Moto MX Eschtec Kids Cup 65',
        nameFr: 'Swiss Moto MX Eschtec Kids Cup 65',
        shortNameDe: 'Swiss Moto MX Eschtec Kids Cup 65',
        shortNameEn: 'Swiss Moto MX Eschtec Kids Cup 65',
        shortNameFr: 'Swiss Moto MX Eschtec Kids Cup 65',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Eschtec Cup 65',
        startListName: 'Eschtec Cup 65',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 50, licenseType: 'national' },
          { association: 'fms', value: 440, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 30,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 50,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2017 bis 2013',
        hintEn: 'Born between 2017 and 2013',
        hintFr: 'Né entre 2017 et 2013',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 30,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 40,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-jeremy-seewer-cup-50',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Swiss Moto MX Jeremy Seewer Cup 50',
    commonNameEn: 'Swiss Moto MX Jeremy Seewer Cup 50',
    commonNameFr: 'Swiss Moto MX Jeremy Seewer Cup 50',
    importName: 'Swiss Moto MX Jeremy Seewer Cup 50',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Jeremy Seewer Cup',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1264,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss Moto MX Jeremy Seewer Cup 50',
        nameEn: 'Swiss Moto MX Jeremy Seewer Cup 50',
        nameFr: 'Swiss Moto MX Jeremy Seewer Cup 50',
        shortNameDe: 'Swiss Moto MX Jeremy Seewer Cup 50',
        shortNameEn: 'Swiss Moto MX Jeremy Seewer Cup 50',
        shortNameFr: 'Swiss Moto MX Jeremy Seewer Cup 50',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Jeremy Seewer Cup',
        startListName: 'Jeremy Seewer Cup',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 50, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 50,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 50,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2020 bis 2016',
        hintEn: 'Born between 2020 and 2016',
        hintFr: 'Né entre 2020 et 2016',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-yamaha-yz-cup',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Swiss Moto MX Yamaha YZ-Cup',
    commonNameEn: 'Swiss Moto MX Yamaha YZ-Cup',
    commonNameFr: 'Swiss Moto MX Yamaha YZ-Cup',
    importName: 'Swiss Moto MX Yamaha YZ-Cup',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Yamaha YZ-Cup',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1114,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss Moto MX Yamaha YZ-Cup',
        nameEn: 'Swiss Moto MX Yamaha YZ-Cup',
        nameFr: 'Swiss Moto MX Yamaha YZ-Cup',
        shortNameDe: 'Swiss Moto MX Yamaha YZ-Cup',
        shortNameEn: 'Swiss Moto MX Yamaha YZ-Cup',
        shortNameFr: 'Swiss Moto MX Yamaha YZ-Cup',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Yamaha YZ-Cup',
        startListName: 'Yamaha YZ-Cup',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 170, to: '2007-01-01', licenseType: 'national' },
          { association: 'fms', value: 440, from: '2006-12-31', licenseType: 'national' },
          { association: 'fms', value: 600, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 90,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2011 und älter',
        hintEn: 'Born in 2011 and older',
        hintFr: 'Année de naissance 2011 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-women',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF', 'MyLaps'],
    numberChoice: true,
    commonNameDe: 'Swiss Moto MX Women',
    commonNameEn: 'Swiss Moto MX Women',
    commonNameFr: 'Swiss Moto MX Women',
    importName: 'MX Women',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'Swiss Moto MX Women',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1265,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss Moto MX Women',
        nameEn: 'Swiss Moto MX Women',
        nameFr: 'Swiss Moto MX Women',
        shortNameDe: 'Women',
        shortNameEn: 'Women',
        shortNameFr: 'Women',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Swiss Moto MX Women',
        startListName: 'Swiss Moto MX Women',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 170, to: '2007-01-01', licenseType: 'national' },
          { association: 'fms', value: 380, from: '2006-12-31', licenseType: 'national' },
          { association: 'fms', value: 600, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 90,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2012 und älter',
        hintEn: 'Born in 2012 and older',
        hintFr: 'Année de naissance 2012 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-women-cup-angora',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF', 'MyLaps'],
    numberChoice: true,
    commonNameDe: 'Women Cup Angora',
    commonNameEn: 'Women Cup Angora',
    commonNameFr: 'Women Cup Angora',
    importName: 'Swiss MX Women Cup Angora',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: 'Swiss MX Women Cup',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1116,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Women Cup Angora',
        nameEn: 'Women Cup Angora',
        nameFr: 'Women Cup Angora',
        shortNameDe: 'Swiss MX Women Cup Angora/AFM',
        shortNameEn: 'Women Cup Angora',
        shortNameFr: 'Women Cup Angora',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Swiss MX Women Cup',
        startListName: 'Swiss MX Women Cup',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 0, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 90,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2012 und älter',
        hintEn: 'Born in 2012 and older',
        hintFr: 'Année de naissance 2012 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: false,
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-mx-quad',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'MX Quad',
    commonNameEn: 'MX Quad',
    commonNameFr: 'MX Quad',
    importName: 'MX Quad',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1117,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'MX Quad',
        nameEn: 'MX Quad',
        nameFr: 'MX Quad',
        shortNameDe: 'MX Quad',
        shortNameEn: 'MX Quad',
        shortNameFr: 'MX Quad',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 350, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-fim-mxgp-wm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIM MXGP WM',
    commonNameEn: 'FIM MXGP WM',
    commonNameFr: 'FIM MXGP WM',
    importName: 'MX FIM MXGP WM',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1118,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM MXGP WM',
        nameEn: 'FIM MXGP WM',
        nameFr: 'FIM MXGP WM',
        shortNameDe: 'FIM MXGP WM',
        shortNameEn: 'FIM MXGP WM',
        shortNameFr: 'FIM MXGP WM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1960, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 1410,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-fim-mx-2-wm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIM MX2 WM',
    commonNameEn: 'FIM MX2 WM',
    commonNameFr: 'FIM MX2 WM',
    importName: 'MX FIM MX2 WM',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1119,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM MX2 WM',
        nameEn: 'FIM MX2 WM',
        nameFr: 'FIM MX2 WM',
        shortNameDe: 'FIM MX2 WM',
        shortNameEn: 'FIM MX2 WM',
        shortNameFr: 'FIM MX2 WM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1961, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 1411,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-fim-sidecar-pilot-wm',
    active: true,
    archived: false,
    sidecar: true,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIM Sidecar Pilot WM',
    commonNameEn: 'FIM Sidecar Pilot WM',
    commonNameFr: 'FIM Sidecar Pilot WM',
    importName: 'MX FIM Sidecar Pilot WM',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1120,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Sidecar Pilot WM',
        nameEn: 'FIM Sidecar Pilot WM',
        nameFr: 'FIM Sidecar Pilot WM',
        shortNameDe: 'FIM Sidecar Pilot WM',
        shortNameEn: 'FIM Sidecar Pilot WM',
        shortNameFr: 'FIM Sidecar Pilot WM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 940, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 390,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-fim-sidecar-passenger-wm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: true,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIM Sidecar Passager WM',
    commonNameEn: 'FIM Sidecar Passager WM',
    commonNameFr: 'FIM Sidecar Passager WM',
    importName: 'MX FIM Sidecar Passenger WM',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1121,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Sidecar Passager WM',
        nameEn: 'FIM Sidecar Passager WM',
        nameFr: 'FIM Sidecar Passager WM',
        shortNameDe: 'FIM Sidecar Passenger WM',
        shortNameEn: 'FIM Sidecar Passenger WM',
        shortNameFr: 'FIM Sidecar Passenger WM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 725, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 275,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-fim-wmx',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIM WMX',
    commonNameEn: 'FIM WMX',
    commonNameFr: 'FIM WMX',
    importName: 'MX FIM WMX',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1122,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM WMX',
        nameEn: 'FIM WMX',
        nameFr: 'FIM WMX',
        shortNameDe: 'FIM WMX',
        shortNameEn: 'FIM WMX',
        shortNameFr: 'FIM WMX',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 825, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 275,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-fime-emx-65',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIME EMX65',
    commonNameEn: 'FIME EMX65',
    commonNameFr: 'FIME EMX65',
    importName: 'MX FIME EMX65',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1123,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME EMX65',
        nameEn: 'FIME EMX65',
        nameFr: 'FIME EMX65',
        shortNameDe: 'FIME EMX65',
        shortNameEn: 'FIME EMX65',
        shortNameFr: 'FIME EMX65',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 555, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 165,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-fime-emx-85',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIME EMX85',
    commonNameEn: 'FIME EMX85',
    commonNameFr: 'FIME EMX85',
    importName: 'MX FIME EMX85',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1124,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME EMX85',
        nameEn: 'FIME EMX85',
        nameFr: 'FIME EMX85',
        shortNameDe: 'FIME EMX85',
        shortNameEn: 'FIME EMX85',
        shortNameFr: 'FIME EMX85',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 555, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 165,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-fime-emx-125',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIME EMX125',
    commonNameEn: 'FIME EMX125',
    commonNameFr: 'FIME EMX125',
    importName: 'MX FIME EMX125',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1125,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME EMX125',
        nameEn: 'FIME EMX125',
        nameFr: 'FIME EMX125',
        shortNameDe: 'FIME EMX125',
        shortNameEn: 'FIME EMX125',
        shortNameFr: 'FIME EMX125',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 665, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 275,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-fime-emx-250',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIME EMX250',
    commonNameEn: 'FIME EMX250',
    commonNameFr: 'FIME EMX250',
    importName: 'MX FIME EMX250',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1126,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME EMX250',
        nameEn: 'FIME EMX250',
        nameFr: 'FIME EMX250',
        shortNameDe: 'FIME EMX250',
        shortNameEn: 'FIME EMX250',
        shortNameFr: 'FIME EMX250',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 825, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 275,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-fim-emx-2-t',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIME EMX2T',
    commonNameEn: 'FIME EMX2T',
    commonNameFr: 'FIME EMX2T',
    importName: 'MX FIME EMX2T',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1127,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME EMX2T',
        nameEn: 'FIME EMX2T',
        nameFr: 'FIME EMX2T',
        shortNameDe: 'FIME EMX2T',
        shortNameEn: 'FIME EMX2T',
        shortNameFr: 'FIME EMX2T',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 825, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 275,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-fime-emx-open',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIME EMX Open',
    commonNameEn: 'FIME EMX Open',
    commonNameFr: 'FIME EMX Open',
    importName: 'MX FIME EMX Open',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1128,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME EMX Open',
        nameEn: 'FIME EMX Open',
        nameFr: 'FIME EMX Open',
        shortNameDe: 'FIME EMX Open',
        shortNameEn: 'FIME EMX Open',
        shortNameFr: 'FIME EMX Open',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 825, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 275,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-fime-wemx',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIME WEMX',
    commonNameEn: 'FIME WEMX',
    commonNameFr: 'FIME WEMX',
    importName: 'MX FIME WEMX',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1129,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME WEMX',
        nameEn: 'FIME WEMX',
        nameFr: 'FIME WEMX',
        shortNameDe: 'FIME WEMX',
        shortNameEn: 'FIME WEMX',
        shortNameFr: 'FIME WEMX',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 555, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 165,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 90,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 70,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-supermoto-quad',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Quad',
    commonNameEn: 'Quad',
    commonNameFr: 'Quad',
    importName: 'SM Quad',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'QUAD',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1266,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Quad',
        nameEn: 'Quad',
        nameFr: 'Quad',
        shortNameDe: 'Quad',
        shortNameEn: 'Quad',
        shortNameFr: 'Quad',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'QUAD',
        startListName: 'QUAD',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 350, licenseType: 'national' },
          { association: 'fms', value: 450, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2010 und älter',
        hintEn: 'Born in 2010 and older',
        hintFr: 'Année de naissance 2010 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 70,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-supermoto-fim-supermoto-s-1-wm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIM Supermoto S1 WM',
    commonNameEn: 'FIM Supermoto S1 WM',
    commonNameFr: 'FIM Supermoto S1 WM',
    importName: 'SM FIM Supermoto S1 WM',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1130,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Supermoto S1 WM',
        nameEn: 'FIM Supermoto S1 WM',
        nameFr: 'FIM Supermoto S1 WM',
        shortNameDe: 'FIM Supermoto S1 WM',
        shortNameEn: 'FIM Supermoto S1 WM',
        shortNameFr: 'FIM Supermoto S1 WM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1170, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 620,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-supermoto-fime-supermoto-s-4-em',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIME Supermoto S4 EM',
    commonNameEn: 'FIME Supermoto S4 EM',
    commonNameFr: 'FIME Supermoto S4 EM',
    importName: 'SM FIME Supermoto S4 EM',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1131,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Supermoto S4 EM',
        nameEn: 'FIME Supermoto S4 EM',
        nameFr: 'FIME Supermoto S4 EM',
        shortNameDe: 'FIME Supermoto S4 EM',
        shortNameEn: 'FIME Supermoto S4 EM',
        shortNameFr: 'FIME Supermoto S4 EM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 825, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 275,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-trial-elite',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Elite',
    commonNameEn: 'Elite',
    commonNameFr: 'Elite',
    importName: 'Trial Elite',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'ELITE',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1132,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Elite',
        nameEn: 'Elite',
        nameFr: 'Elite',
        shortNameDe: 'Elite',
        shortNameEn: 'Elite',
        shortNameFr: 'Elite',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'ELITE',
        startListName: 'ELITE',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-trial-expert',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Expert',
    commonNameEn: 'Expert',
    commonNameFr: 'Expert',
    importName: 'Trial Expert',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'EXPERT',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1133,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Expert',
        nameEn: 'Expert',
        nameFr: 'Expert',
        shortNameDe: 'Expert',
        shortNameEn: 'Expert',
        shortNameFr: 'Expert',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: 'EXPERT',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 390, licenseType: 'national' },
          { association: 'fms', value: 550, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-trial-open',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Open',
    commonNameEn: 'Open',
    commonNameFr: 'Open',
    importName: 'Trial Open',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'OPEN',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1134,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Open',
        nameEn: 'Open',
        nameFr: 'Open',
        shortNameDe: 'Open',
        shortNameEn: 'Open',
        shortNameFr: 'Open',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: 'OPEN',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 110, to: '2007-01-01', licenseType: 'national' },
          {
            association: 'fms',
            value: 220,
            from: '2006-12-31',
            to: '2004-01-01',
            licenseType: 'national',
          },
          { association: 'fms', value: 390, from: '2003-12-31', licenseType: 'national' },
          { association: 'fms', value: 390, to: '2004-01-01', licenseType: 'international' },
          { association: 'fms', value: 550, from: '2003-12-31', licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-trial-challenge',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Challenge',
    commonNameEn: 'Challenge',
    commonNameFr: 'Challenge',
    importName: 'Trial Challenge',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'CHALLENGE',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1135,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Challenge',
        nameEn: 'Challenge',
        nameFr: 'Challenge',
        shortNameDe: 'Challenge',
        shortNameEn: 'Challenge',
        shortNameFr: 'Challenge',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: 'CHALLENGE',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 110, to: '2007-01-01', licenseType: 'national' },
          {
            association: 'fms',
            value: 220,
            from: '2006-12-31',
            to: '2004-01-01',
            licenseType: 'national',
          },
          { association: 'fms', value: 390, from: '2003-12-31', licenseType: 'national' },
          { association: 'fms', value: 390, to: '2004-01-01', licenseType: 'international' },
          { association: 'fms', value: 550, from: '2003-12-31', licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-trial-junior',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Junior',
    commonNameEn: 'Junior',
    commonNameFr: 'Junior',
    importName: 'Trial Junior',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'JUNIOR',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1136,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Junior',
        nameEn: 'Junior',
        nameFr: 'Junior',
        shortNameDe: 'Junior',
        shortNameEn: 'Junior',
        shortNameFr: 'Junior',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: 'JUNIOR',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 110, to: '2007-01-01', licenseType: 'national' },
          { association: 'fms', value: 220, from: '2006-12-31', licenseType: 'national' },
          { association: 'fms', value: 390, to: '2004-01-01', licenseType: 'international' },
          { association: 'fms', value: 550, from: '2003-12-31', licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2005 und jünger',
        hintEn: 'Born 2005 and younger',
        hintFr: 'Né en 2005 et moins',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-trial-fun',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Fun',
    commonNameEn: 'Fun',
    commonNameFr: 'Fun',
    importName: 'Trial Fun',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'FUN',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1137,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Fun',
        nameEn: 'Fun',
        nameFr: 'Fun',
        shortNameDe: 'Fun',
        shortNameEn: 'Fun',
        shortNameFr: 'Fun',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: 'FUN',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 160, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-trial-assistent',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Assistent',
    commonNameEn: 'Assistent',
    commonNameFr: 'Assistent',
    importName: 'Trial Assistent',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'ASSISTENT',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1138,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Assistent',
        nameEn: 'Assistent',
        nameFr: 'Assistent',
        shortNameDe: 'Assistent',
        shortNameEn: 'Assistent',
        shortNameFr: 'Assistent',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: 'ASSISTENT',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 60, licenseType: 'national' },
          { association: 'fms', value: 110, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: false,
        priceAdditionalLicense: 60,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-trial-fime-trial',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Trial EM / CE',
    commonNameEn: 'FIME Trial EM / CE',
    commonNameFr: 'FIME Trial EM / CE',
    importName: 'Trial FIME Trial',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1139,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Trial EM / CE',
        nameEn: 'FIME Trial EM / CE',
        nameFr: 'FIME Trial EM / CE',
        shortNameDe: 'FIME Trial EM / CE',
        shortNameEn: 'FIME Trial EM / CE',
        shortNameFr: 'FIME Trial EM / CE',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 775, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 275,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-trial-fim-trial-x-trial',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Trial / X Trial',
    commonNameEn: 'FIM Trial / X Trial',
    commonNameFr: 'FIM Trial / X Trial',
    importName: 'Trial FIM Trial / X Trial',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1140,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Trial / X Trial',
        nameEn: 'FIM Trial / X Trial',
        nameFr: 'FIM Trial / X Trial',
        shortNameDe: 'FIM Trial / X Trial',
        shortNameEn: 'FIM Trial / X Trial',
        shortNameFr: 'FIM Trial / X Trial',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1120, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 620,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-trial-fim-trial-women',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Trial Women',
    commonNameEn: 'FIM Trial Women',
    commonNameFr: 'FIM Trial Women',
    importName: 'Trial FIM Trial Women',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1141,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Trial Women',
        nameEn: 'FIM Trial Women',
        nameFr: 'FIM Trial Women',
        shortNameDe: 'FIM Trial Women',
        shortNameEn: 'FIM Trial Women',
        shortNameFr: 'FIM Trial Women',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 775, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 300,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-trial-fim-trial-assistant',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Trial Assistant',
    commonNameEn: 'FIM Trial Assistant',
    commonNameFr: 'FIM Trial Assistant',
    importName: 'Trial FIM Trial Assistant',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1142,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Trial Assistant',
        nameEn: 'FIM Trial Assistant',
        nameFr: 'FIM Trial Assistant',
        shortNameDe: 'FIM Trial Assistant',
        shortNameEn: 'FIM Trial Assistant',
        shortNameFr: 'FIM Trial Assistant',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 225, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: false,
        priceAdditionalLicense: 150,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-trial-fime-trial-assistant',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Trial Assistant',
    commonNameEn: 'FIME Trial Assistant',
    commonNameFr: 'FIME Trial Assistant',
    importName: 'Trial FIME Trial Assistant',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1143,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Trial Assistant',
        nameEn: 'FIME Trial Assistant',
        nameFr: 'FIME Trial Assistant',
        shortNameDe: 'FIME Trial Assistant',
        shortNameEn: 'FIME Trial Assistant',
        shortNameFr: 'FIME Trial Assistant',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 225, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: false,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-trial-kids-cup',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Swiss Moto Trial Kids Cup',
    commonNameEn: 'Swiss Moto Trial Kids Cup',
    commonNameFr: 'Swiss Moto Trial Kids Cup',
    importName: 'Trial Kids Cup',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: 'Trial Kids Cup',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1267,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss Moto Trial Kids Cup',
        nameEn: 'Swiss Moto Trial Kids Cup',
        nameFr: 'Swiss Moto Trial Kids Cup',
        shortNameDe: 'Swiss Moto Trial Kids Cup',
        shortNameEn: 'Swiss Moto Trial Kids Cup',
        shortNameFr: 'Swiss Moto Trial Kids Cup',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Trial Kids Cup',
        startListName: 'Trial Kids Cup',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b58',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 60, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 60,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 20,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 20,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahgamg 2012 - 2017',
        hintEn: 'Born between 2017 and 2012',
        hintFr: 'Né en 2012 a 2017',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-enduro-inter-open',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Inter Open',
    commonNameEn: 'Inter Open',
    commonNameFr: 'Inter Open',
    importName: 'Enduro Inter Open',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1144,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Open',
        nameEn: 'Inter Open',
        nameFr: 'Inter Open',
        shortNameDe: 'Inter Open',
        shortNameEn: 'Inter Open',
        shortNameFr: 'Inter Open',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 440, to: '1999-01-01', licenseType: 'international' },
          { association: 'fms', value: 600, from: '1998-12-31', licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 120,
        discountInscriptionWithLicenseTwoDays: -70,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2007 und älter',
        hintEn: 'Born 2007 and older',
        hintFr: 'Né en 2007 et plus',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-enduro-inter-junior-25',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Inter Junior -25',
    commonNameEn: 'Inter Junior -25',
    commonNameFr: 'Inter Junior -25',
    importName: 'Enduro Inter Junior -25',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1145,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Junior -25',
        nameEn: 'Inter Junior -25',
        nameFr: 'Inter Junior -25',
        shortNameDe: 'Inter Junior -25',
        shortNameEn: 'Inter Junior -25',
        shortNameFr: 'Inter Junior -25',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 440, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 90,
        discountInscriptionWithLicenseTwoDays: -130,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2007 bis 2000',
        hintEn: 'Vintage 2007 to 2000',
        hintFr: 'Millésime 2007 à 2000',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-enduro-national-open',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'National Open',
    commonNameEn: 'National Open',
    commonNameFr: 'National Open',
    importName: 'Enduro National Open',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1146,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'National Open',
        nameEn: 'National Open',
        nameFr: 'National Open',
        shortNameDe: 'National Open',
        shortNameEn: 'National Open',
        shortNameFr: 'National Open',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 280, to: '1999-01-01', licenseType: 'national' },
          { association: 'fms', value: 440, from: '1998-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 120,
        discountInscriptionWithLicenseTwoDays: -70,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2007 und älter',
        hintEn: 'Born 2007 and older',
        hintFr: 'Né en 2007 et plus',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-enduro-national-junior-25',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'National Junior -25',
    commonNameEn: 'National Junior -25',
    commonNameFr: 'National Junior -25',
    importName: 'Enduro National Junior -25',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1147,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'National Junior -25',
        nameEn: 'National Junior -25',
        nameFr: 'National Junior -25',
        shortNameDe: 'National Junior -25',
        shortNameEn: 'National Junior -25',
        shortNameFr: 'National Junior -25',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 280, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 90,
        discountInscriptionWithLicenseTwoDays: -130,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2000 und älter',
        hintEn: 'Born 2000 and older',
        hintFr: 'Né en 2000 et plus',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-enduro-veteran',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Veteran',
    commonNameEn: 'Vétéran',
    commonNameFr: 'Veteran',
    importName: 'Enduro Veteran',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1148,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Veteran',
        nameEn: 'Veteran',
        nameFr: 'Vétéran',
        shortNameDe: 'Veteran',
        shortNameEn: 'Veteran',
        shortNameFr: 'Veteran',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 440, licenseType: 'national' },
          { association: 'fms', value: 600, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 120,
        discountInscriptionWithLicenseTwoDays: -70,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 1980 und älter',
        hintEn: 'Born in 1980 and older',
        hintFr: 'Né en 1980 et plus',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-enduro-senior',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Senior',
    commonNameEn: 'Senior',
    commonNameFr: 'Senior',
    importName: 'Enduro Senior',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1149,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Senior',
        nameEn: 'Senior',
        nameFr: 'Senior',
        shortNameDe: 'Senior',
        shortNameEn: 'Senior',
        shortNameFr: 'Senior',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 440, licenseType: 'national' },
          { association: 'fms', value: 600, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 120,
        discountInscriptionWithLicenseTwoDays: -70,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 1985 und älter',
        hintEn: 'Born in 1985 and older',
        hintFr: 'Né en 1985 et plus',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-enduro-kadetten',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Kadetten',
    commonNameEn: 'Cadet',
    commonNameFr: 'Kadetten',
    importName: 'Enduro Kadetten',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1150,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Kadetten',
        nameEn: 'Kadetten',
        nameFr: 'Cadet',
        shortNameDe: 'Kadetten',
        shortNameEn: 'Kadetten',
        shortNameFr: 'Cadet',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 110, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2009 bis 2007',
        hintEn: 'Vintage 2009 to 2007',
        hintFr: 'Millésime 2009 à 2007',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-enduro-women',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Women',
    commonNameEn: 'Women',
    commonNameFr: 'Women',
    importName: 'Enduro Women',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1151,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Women',
        nameEn: 'Women',
        nameFr: 'Women',
        shortNameDe: 'Women',
        shortNameEn: 'Women',
        shortNameFr: 'Women',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 440, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 120,
        discountInscriptionWithLicenseTwoDays: -70,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2007 und älter',
        hintEn: 'Born 2007 and older',
        hintFr: 'Né en 2007 et plus',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-enduro-promo',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Promo',
    commonNameEn: 'Promo',
    commonNameFr: 'Promo',
    importName: 'Enduro Promo',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1152,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Promo',
        nameEn: 'Promo',
        nameFr: 'Promo',
        shortNameDe: 'Promo',
        shortNameEn: 'Promo',
        shortNameFr: 'Promo',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 180, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 120,
        discountInscriptionWithLicenseTwoDays: -70,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2007 und älter',
        hintEn: 'Born 2007 and older',
        hintFr: 'Né en 2007 et plus',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 90,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-enduro-vintage',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Vintage',
    commonNameEn: 'Vintage',
    commonNameFr: 'Vintage',
    importName: 'Enduro Vintage',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1153,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Vintage',
        nameEn: 'Vintage',
        nameFr: 'Vintage',
        shortNameDe: 'Vintage',
        shortNameEn: 'Vintage',
        shortNameFr: 'Vintage',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 440, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2007 und älter',
        hintEn: 'Jahrgang 2007 und älter',
        hintFr: 'Jahrgang 2007 und älter',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 90,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-enduro-enduro-rally',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Enduro Rally',
    commonNameEn: 'Enduro Rally',
    commonNameFr: 'Enduro Rally',
    importName: 'Enduro Rally',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1154,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Enduro Rally',
        nameEn: 'Enduro Rally',
        nameFr: 'Enduro Rally',
        shortNameDe: 'Enduro Rally',
        shortNameEn: 'Enduro Rally',
        shortNameFr: 'Enduro Rally',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 600, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: false,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-enduro-vintage-enduro-european-championship',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Vintage Enduro European Championship',
    commonNameEn: 'Vintage Enduro European Championship',
    commonNameFr: 'Vintage Enduro European Championship',
    importName: 'Enduro Vintage Enduro European Championship',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1155,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Vintage Enduro European Championship',
        nameEn: 'Vintage Enduro European Championship',
        nameFr: 'Vintage Enduro European Championship',
        shortNameDe: 'Vintage Enduro European Championship',
        shortNameEn: 'Vintage Enduro European Championship',
        shortNameFr: 'Vintage Enduro European Championship',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 650, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 150,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-enduro-fime-enduro',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Enduro',
    commonNameEn: 'FIME Enduro',
    commonNameFr: 'FIME Enduro',
    importName: 'Enduro FIME Enduro',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1156,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Enduro',
        nameEn: 'FIME Enduro',
        nameFr: 'FIME Enduro',
        shortNameDe: 'FIME Enduro',
        shortNameEn: 'FIME Enduro',
        shortNameFr: 'FIME Enduro',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 825, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 275,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-enduro-fime-enduro-junior',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Enduro Junior',
    commonNameEn: 'FIME Enduro Junior',
    commonNameFr: 'FIME Enduro Junior',
    importName: 'Enduro FIME Enduro Junior',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1157,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Enduro Junior',
        nameEn: 'FIME Enduro Junior',
        nameFr: 'FIME Enduro Junior',
        shortNameDe: 'FIME Enduro Junior',
        shortNameEn: 'FIME Enduro Junior',
        shortNameFr: 'FIME Enduro Junior',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 665, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 275,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-enduro-fim-enduro-isde',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Enduro / ISDE',
    commonNameEn: 'FIM Enduro / ISDE',
    commonNameFr: 'FIM Enduro / ISDE',
    importName: 'Enduro FIM Enduro / ISDE',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1158,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Enduro / ISDE',
        nameEn: 'FIM Enduro / ISDE',
        nameFr: 'FIM Enduro / ISDE',
        shortNameDe: 'FIM Enduro / ISDE',
        shortNameEn: 'FIM Enduro / ISDE',
        shortNameFr: 'FIM Enduro / ISDE',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1170, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 620,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-enduro-fim-enduro-junior',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Enduro Junior',
    commonNameEn: 'FIM Enduro Junior',
    commonNameFr: 'FIM Enduro Junior',
    importName: 'Enduro FIM Enduro Junior',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1159,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Enduro Junior',
        nameEn: 'FIM Enduro Junior',
        nameFr: 'FIM Enduro Junior',
        shortNameDe: 'FIM Enduro Junior',
        shortNameEn: 'FIM Enduro Junior',
        shortNameFr: 'FIM Enduro Junior',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 665, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 300,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-enduro-fim-womens-world-cup',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: "FIM Women's World Cup",
    commonNameEn: "FIM Women's World Cup",
    commonNameFr: "FIM Women's World Cup",
    importName: "Enduro FIM Women's World Cup",
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1160,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: "FIM Women's World Cup",
        nameEn: "FIM Women's World Cup",
        nameFr: "FIM Women's World Cup",
        shortNameDe: "FIM Women's World Cup",
        shortNameEn: "FIM Women's World Cup",
        shortNameFr: "FIM Women's World Cup",
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 665, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 300,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-enduro-fim-super-enduro',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM SuperEnduro',
    commonNameEn: 'FIM SuperEnduro',
    commonNameFr: 'FIM SuperEnduro',
    importName: 'Enduro FIM SuperEnduro',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1161,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM SuperEnduro',
        nameEn: 'FIM SuperEnduro',
        nameFr: 'FIM SuperEnduro',
        shortNameDe: 'FIM SuperEnduro',
        shortNameEn: 'FIM SuperEnduro',
        shortNameFr: 'FIM SuperEnduro',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 825, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 275,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-vintage-solo',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'vintage',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Solo',
    commonNameEn: 'Solo',
    commonNameFr: 'Solo',
    importName: 'Vintage Solo',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1162,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Solo',
        nameEn: 'Solo',
        nameFr: 'Solo',
        shortNameDe: 'Solo',
        shortNameEn: 'Solo',
        shortNameFr: 'Solo',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 220, licenseType: 'national' },
          { association: 'fms', value: 440, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-vintage-sidecar-pilot',
    active: true,
    archived: false,
    sidecar: true,
    sidecarPassenger: false,
    type: 'vintage',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Sidecar Pilot',
    commonNameEn: 'Sidecar Pilot',
    commonNameFr: 'Sidecar Pilot',
    importName: 'Vintage Sidecar Pilot',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1163,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Sidecar Pilot',
        nameEn: 'Sidecar Pilot',
        nameFr: 'Sidecar Pilot',
        shortNameDe: 'Sidecar Pilot',
        shortNameEn: 'Sidecar Pilot',
        shortNameFr: 'Sidecar Pilot',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 220, licenseType: 'national' },
          { association: 'fms', value: 440, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-vintage-sidecar-passager',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'vintage',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Sidecar Passager',
    commonNameEn: 'Sidecar Passager',
    commonNameFr: 'Sidecar Passager',
    importName: 'Vintage Sidecar Passager',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1164,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Sidecar Passager',
        nameEn: 'Sidecar Passager',
        nameFr: 'Sidecar Passager',
        shortNameDe: 'Sidecar Passager',
        shortNameEn: 'Sidecar Passager',
        shortNameFr: 'Sidecar Passager',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 220, licenseType: 'national' },
          { association: 'fms', value: 330, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-vintage-uem-cup',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'vintage',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'UEM Cup',
    commonNameEn: 'UEM Cup',
    commonNameFr: 'UEM Cup',
    importName: 'Vintage UEM Cup',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1165,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'UEM Cup',
        nameEn: 'UEM Cup',
        nameFr: 'UEM Cup',
        shortNameDe: 'UEM Cup',
        shortNameEn: 'UEM Cup',
        shortNameFr: 'UEM Cup',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 440, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-bergrennen-fime-bergrennen-course-de-cote',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'bergrennen',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Bergrennen / Course de Côte',
    commonNameEn: 'FIME Bergrennen / Course de Côte',
    commonNameFr: 'FIME Bergrennen / Course de Côte',
    importName: 'Bergrennen FIME Bergrennen / Course de Côte',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1166,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Bergrennen / Course de Côte',
        nameEn: 'FIME Bergrennen / Course de Côte',
        nameFr: 'FIME Bergrennen / Course de Côte',
        shortNameDe: 'FIME Bergrennen / Course de Côte',
        shortNameEn: 'FIME Bergrennen / Course de Côte',
        shortNameFr: 'FIME Bergrennen / Course de Côte',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 825, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 275,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-bergrennen-swiss-moto-legend-trophy',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'bergrennen',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Swiss Moto Legend Trophy',
    commonNameEn: 'Swiss Moto Legend Trophy',
    commonNameFr: 'Swiss Moto Legend Trophy',
    importName: 'Bergrennen Swiss Moto Legend Trophy',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1167,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss Moto Legend Trophy',
        nameEn: 'Swiss Moto Legend Trophy',
        nameFr: 'Swiss Moto Legend Trophy',
        shortNameDe: 'Swiss Moto Legend Trophy',
        shortNameEn: 'Swiss Moto Legend Trophy',
        shortNameFr: 'Swiss Moto Legend Trophy',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 220, licenseType: 'national' },
          { association: 'fms', value: 440, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-bergrennen-swiss-sidecar-legend-trophy',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'bergrennen',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Swiss Sidecar Legend Trophy',
    commonNameEn: 'Swiss Sidecar Legend Trophy',
    commonNameFr: 'Swiss Sidecar Legend Trophy',
    importName: 'Bergrennen Swiss Sidecar Legend Trophy',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1168,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss Sidecar Legend Trophy',
        nameEn: 'Swiss Sidecar Legend Trophy',
        nameFr: 'Swiss Sidecar Legend Trophy',
        shortNameDe: 'Swiss Sidecar Legend Trophy',
        shortNameEn: 'Swiss Sidecar Legend Trophy',
        shortNameFr: 'Swiss Sidecar Legend Trophy',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 220, licenseType: 'national' },
          { association: 'fms', value: 440, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-bergrennen-solo',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'bergrennen',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Solo',
    commonNameEn: 'Solo',
    commonNameFr: 'Solo',
    importName: 'Bergrennen Solo',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1169,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Solo',
        nameEn: 'Solo',
        nameFr: 'Solo',
        shortNameDe: 'Solo',
        shortNameEn: 'Solo',
        shortNameFr: 'Solo',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 440, licenseType: 'national' },
          { association: 'fms', value: 600, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-bergrennen-sidecar-pilot',
    active: true,
    archived: false,
    sidecar: true,
    sidecarPassenger: false,
    type: 'bergrennen',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Sidecar Pilot',
    commonNameEn: 'Sidecar Pilot',
    commonNameFr: 'Sidecar Pilot',
    importName: 'Bergrennen Sidecar Pilot',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1170,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Sidecar Pilot',
        nameEn: 'Sidecar Pilot',
        nameFr: 'Sidecar Pilot',
        shortNameDe: 'Sidecar Pilot',
        shortNameEn: 'Sidecar Pilot',
        shortNameFr: 'Sidecar Pilot',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 440, licenseType: 'national' },
          { association: 'fms', value: 600, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-bergrennen-sidecar-passager',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'bergrennen',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Sidecar Passager',
    commonNameEn: 'Sidecar Passager',
    commonNameFr: 'Sidecar Passager',
    importName: 'Bergrennen Sidecar Passager',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1171,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Sidecar Passager',
        nameEn: 'Sidecar Passager',
        nameFr: 'Sidecar Passager',
        shortNameDe: 'Sidecar Passager',
        shortNameEn: 'Sidecar Passager',
        shortNameFr: 'Sidecar Passager',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 330, licenseType: 'national' },
          { association: 'fms', value: 500, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-fim-moto-gp',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Moto GP',
    commonNameEn: 'FIM Moto GP',
    commonNameFr: 'FIM Moto GP',
    importName: 'Road Racing FIM Moto GP',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1172,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Moto GP',
        nameEn: 'FIM Moto GP',
        nameFr: 'FIM Moto GP',
        shortNameDe: 'FIM Moto GP',
        shortNameEn: 'FIM Moto GP',
        shortNameFr: 'FIM Moto GP',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 0, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Verrechnung durch IRTA',
        hintEn: 'Settlement by IRTA',
        hintFr: "Règlement par l'IRTA",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-fim-moto-2',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Moto2',
    commonNameEn: 'FIM Moto2',
    commonNameFr: 'FIM Moto2',
    importName: 'Road Racing FIM Moto2',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1173,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Moto2',
        nameEn: 'FIM Moto2',
        nameFr: 'FIM Moto2',
        shortNameDe: 'FIM Moto2',
        shortNameEn: 'FIM Moto2',
        shortNameFr: 'FIM Moto2',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 0, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Verrechnung durch IRTA',
        hintEn: 'Settlement by IRTA',
        hintFr: "Règlement par l'IRTA",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-fim-moto-3',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Moto3',
    commonNameEn: 'FIM Moto3',
    commonNameFr: 'FIM Moto3',
    importName: 'Road Racing FIM Moto3',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1174,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Moto3',
        nameEn: 'FIM Moto3',
        nameFr: 'FIM Moto3',
        shortNameDe: 'FIM Moto3',
        shortNameEn: 'FIM Moto3',
        shortNameFr: 'FIM Moto3',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 0, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Verrechnung durch IRTA',
        hintEn: 'Settlement by IRTA',
        hintFr: "Règlement par l'IRTA",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-fim-moto-e',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM MotoE',
    commonNameEn: 'FIM MotoE',
    commonNameFr: 'FIM MotoE',
    importName: 'Road Racing FIM MotoE',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1175,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM MotoE',
        nameEn: 'FIM MotoE',
        nameFr: 'FIM MotoE',
        shortNameDe: 'FIM MotoE',
        shortNameEn: 'FIM MotoE',
        shortNameFr: 'FIM MotoE',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'international' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 0, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Verrechnung durch IRTA',
        hintEn: 'Settlement by IRTA',
        hintFr: "Règlement par l'IRTA",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: false,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-fim-superbike-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Superbike WM/CM',
    commonNameEn: 'FIM Superbike WM/CM',
    commonNameFr: 'FIM Superbike WM/CM',
    importName: 'Road Racing FIM Superbike WM/CM',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1176,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Superbike WM/CM',
        nameEn: 'FIM Superbike WM/CM',
        nameFr: 'FIM Superbike WM/CM',
        shortNameDe: 'FIM Superbike WM/CM',
        shortNameEn: 'FIM Superbike WM/CM',
        shortNameFr: 'FIM Superbike WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 2640, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 1360,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-fim-supersport-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Supersport  WM/CM',
    commonNameEn: 'FIM Supersport  WM/CM',
    commonNameFr: 'FIM Supersport  WM/CM',
    importName: 'Road Racing FIM Supersport  WM/CM',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1177,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Supersport  WM/CM',
        nameEn: 'FIM Supersport  WM/CM',
        nameFr: 'FIM Supersport  WM/CM',
        shortNameDe: 'FIM Supersport  WM/CM',
        shortNameEn: 'FIM Supersport  WM/CM',
        shortNameFr: 'FIM Supersport  WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 2640, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 1360,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-fim-endurance-team-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Endurance Team  WM/CM',
    commonNameEn: 'FIM Endurance Team  WM/CM',
    commonNameFr: 'FIM Endurance Team  WM/CM',
    importName: 'Road Racing FIM Endurance Team  WM/CM',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1178,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Endurance Team  WM/CM',
        nameEn: 'FIM Endurance Team  WM/CM',
        nameFr: 'FIM Endurance Team  WM/CM',
        shortNameDe: 'FIM Endurance Team  WM/CM',
        shortNameEn: 'FIM Endurance Team  WM/CM',
        shortNameFr: 'FIM Endurance Team  WM/CM',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'international' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1135, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: false,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-fim-endurance-rider-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Endurance Rider  WM/CM',
    commonNameEn: 'FIM Endurance Rider  WM/CM',
    commonNameFr: 'FIM Endurance Rider  WM/CM',
    importName: 'Road Racing FIM Endurance Rider  WM/CM',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1179,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Endurance Rider  WM/CM',
        nameEn: 'FIM Endurance Rider  WM/CM',
        nameFr: 'FIM Endurance Rider  WM/CM',
        shortNameDe: 'FIM Endurance Rider  WM/CM',
        shortNameEn: 'FIM Endurance Rider  WM/CM',
        shortNameFr: 'FIM Endurance Rider  WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1170, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 620,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-fim-sidecar-pilote-wm-cm',
    active: true,
    archived: false,
    sidecar: true,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Sidecar Pilote WM/CM',
    commonNameEn: 'FIM Sidecar Pilote WM/CM',
    commonNameFr: 'FIM Sidecar Pilote WM/CM',
    importName: 'Road Racing FIM Sidecar Pilote WM/CM',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1180,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Sidecar Pilote WM/CM',
        nameEn: 'FIM Sidecar Pilote WM/CM',
        nameFr: 'FIM Sidecar Pilote WM/CM',
        shortNameDe: 'FIM Sidecar Pilote WM/CM',
        shortNameEn: 'FIM Sidecar Pilote WM/CM',
        shortNameFr: 'FIM Sidecar Pilote WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 940, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 390,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-fim-sidecar-passager-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Sidecar Passager WM/CM',
    commonNameEn: 'FIM Sidecar Passager WM/CM',
    commonNameFr: 'FIM Sidecar Passager WM/CM',
    importName: 'Road Racing FIM Sidecar Passager WM/CM',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1181,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Sidecar Passager WM/CM',
        nameEn: 'FIM Sidecar Passager WM/CM',
        nameFr: 'FIM Sidecar Passager WM/CM',
        shortNameDe: 'FIM Sidecar Passager WM/CM',
        shortNameEn: 'FIM Sidecar Passager WM/CM',
        shortNameFr: 'FIM Sidecar Passager WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 725, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 275,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-fim-moto-gp-rookies-cup',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM MotoGP Rookies Cup',
    commonNameEn: 'FIM MotoGP Rookies Cup',
    commonNameFr: 'FIM MotoGP Rookies Cup',
    importName: 'Road Racing FIM MotoGP Rookies Cup',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1182,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM MotoGP Rookies Cup',
        nameEn: 'FIM MotoGP Rookies Cup',
        nameFr: 'FIM MotoGP Rookies Cup',
        shortNameDe: 'FIM MotoGP Rookies Cup',
        shortNameEn: 'FIM MotoGP Rookies Cup',
        shortNameFr: 'FIM MotoGP Rookies Cup',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 665, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 275,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-fim-cev-moto-3-junior-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM CEV Moto3 Junior WM / CM',
    commonNameEn: 'FIM CEV Moto3 Junior WM / CM',
    commonNameFr: 'FIM CEV Moto3 Junior WM / CM',
    importName: 'Road Racing FIM CEV Moto3 Junior WM / CM',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1183,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM CEV Moto3 Junior WM / CM',
        nameEn: 'FIM CEV Moto3 Junior WM / CM',
        nameFr: 'FIM CEV Moto3 Junior WM / CM',
        shortNameDe: 'FIM CEV Moto3 Junior WM / CM',
        shortNameEn: 'FIM CEV Moto3 Junior WM / CM',
        shortNameFr: 'FIM CEV Moto3 Junior WM / CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 665, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 275,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-fim-junior-gp-moto-2-european-championship',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM JuniorGP Moto2 / Stock European Championship',
    commonNameEn: 'FIM JuniorGP Moto2 / Stock European Championship',
    commonNameFr: 'FIM JuniorGP Moto2 / Stock European Championship',
    importName: 'FIM JuniorGP Moto2 / Stock European Championship',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1184,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM JuniorGP Moto2 / Stock European Championship',
        nameEn: 'FIM JuniorGP Moto2 / Stock European Championship',
        nameFr: 'FIM JuniorGP Moto2 / Stock European Championship',
        shortNameDe: 'FIM JuniorGP Moto2 / Stock European Championship',
        shortNameEn: 'FIM JuniorGP Moto2 / Stock European Championship',
        shortNameFr: 'FIM JuniorGP Moto2 / Stock European Championship',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 600, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: false,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-fime-road-racing',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Road Racing EM / CE',
    commonNameEn: 'FIME Road Racing EM / CE',
    commonNameFr: 'FIME Road Racing EM / CE',
    importName: 'Road Racing FIME Road Racing',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1185,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Road Racing EM / CE',
        nameEn: 'FIME Road Racing EM / CE',
        nameFr: 'FIME Road Racing EM / CE',
        shortNameDe: 'FIME Road Racing EM / CE',
        shortNameEn: 'FIME Road Racing EM / CE',
        shortNameFr: 'FIME Road Racing EM / CE',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 825, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 275,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Genaue Kategorie bekanntgeben',
        hintEn: 'Announce exact category',
        hintFr: 'Annoncer la catégorie exacte',
        advertiseSamInsurance: false,
        needsCommentDe: 'genaue Kategorie bekanntgeben',
        needsCommentFr: 'State exact category',
        needsCommentEn: 'annoncer la catégorie exacte',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-fime-promosport-mini-rr-pocketbike',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Promosport (Mini RR, Pocketbike)',
    commonNameEn: 'FIME Promosport (Mini RR, Pocketbike)',
    commonNameFr: 'FIME Promosport (Mini RR, Pocketbike)',
    importName: 'Road Racing FIME Promosport (Mini RR, Pocketbike)',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1186,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Promosport (Mini RR, Pocketbike)',
        nameEn: 'FIME Promosport (Mini RR, Pocketbike)',
        nameFr: 'FIME Promosport (Mini RR, Pocketbike)',
        shortNameDe: 'FIME Promosport (Mini RR, Pocketbike)',
        shortNameEn: 'FIME Promosport (Mini RR, Pocketbike)',
        shortNameFr: 'FIME Promosport (Mini RR, Pocketbike)',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 525, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 135,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-fime-northern-talent-cup',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Northern Talent Cup',
    commonNameEn: 'FIME Northern Talent Cup',
    commonNameFr: 'FIME Northern Talent Cup',
    importName: 'Road Racing FIME Northern Talent Cup',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1187,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Northern Talent Cup',
        nameEn: 'FIME Northern Talent Cup',
        nameFr: 'FIME Northern Talent Cup',
        shortNameDe: 'FIME Northern Talent Cup',
        shortNameEn: 'FIME Northern Talent Cup',
        shortNameFr: 'FIME Northern Talent Cup',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 650, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 150,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-inter-supersport-600',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Supersport 600',
    commonNameEn: 'Inter Supersport 600',
    commonNameFr: 'Inter Supersport 600',
    importName: 'Road Racing Inter Supersport 600',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1188,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Supersport 600',
        nameEn: 'Inter Supersport 600',
        nameFr: 'Inter Supersport 600',
        shortNameDe: 'Inter Supersport 600',
        shortNameEn: 'Inter Supersport 600',
        shortNameFr: 'Inter Supersport 600',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 600, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-inter-supersport-300',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Supersport 300',
    commonNameEn: 'Inter Supersport 300',
    commonNameFr: 'Inter Supersport 300',
    importName: 'Road Racing Inter Supersport 300',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1189,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Supersport 300',
        nameEn: 'Inter Supersport 300',
        nameFr: 'Inter Supersport 300',
        shortNameDe: 'Inter Supersport 300',
        shortNameEn: 'Inter Supersport 300',
        shortNameFr: 'Inter Supersport 300',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 600, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-inter-superstock-600',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Superstock 600',
    commonNameEn: 'Inter Superstock 600',
    commonNameFr: 'Inter Superstock 600',
    importName: 'Road Racing Inter Superstock 600',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1190,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Superstock 600',
        nameEn: 'Inter Superstock 600',
        nameFr: 'Inter Superstock 600',
        shortNameDe: 'Inter Superstock 600',
        shortNameEn: 'Inter Superstock 600',
        shortNameFr: 'Inter Superstock 600',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 600, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-inter-superstock-1000',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Superstock 1000',
    commonNameEn: 'Inter Superstock 1000',
    commonNameFr: 'Inter Superstock 1000',
    importName: 'Road Racing Inter Superstock 1000',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1191,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Superstock 1000',
        nameEn: 'Inter Superstock 1000',
        nameFr: 'Inter Superstock 1000',
        shortNameDe: 'Inter Superstock 1000',
        shortNameEn: 'Inter Superstock 1000',
        shortNameFr: 'Inter Superstock 1000',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 600, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-inter-superbike',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Superbike',
    commonNameEn: 'Inter Superbike',
    commonNameFr: 'Inter Superbike',
    importName: 'Road Racing Inter Superbike',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1192,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Superbike',
        nameEn: 'Inter Superbike',
        nameFr: 'Inter Superbike',
        shortNameDe: 'Inter Superbike',
        shortNameEn: 'Inter Superbike',
        shortNameFr: 'Inter Superbike',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 600, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-inter-yamaha-r-3-b-lu-c-ru-cup',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Yamaha R3 bLU cRU Cup',
    commonNameEn: 'Inter Yamaha R3 bLU cRU Cup',
    commonNameFr: 'Inter Yamaha R3 bLU cRU Cup',
    importName: 'Road Racing Inter Yamaha R3 bLU cRU Cup',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1193,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Yamaha R3 bLU cRU Cup',
        nameEn: 'Inter Yamaha R3 bLU cRU Cup',
        nameFr: 'Inter Yamaha R3 bLU cRU Cup',
        shortNameDe: 'Inter Yamaha R3 bLU cRU Cup',
        shortNameEn: 'Inter Yamaha R3 bLU cRU Cup',
        shortNameFr: 'Inter Yamaha R3 bLU cRU Cup',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 600, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-inter-250-cc',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter 250cc',
    commonNameEn: 'Inter 250cc',
    commonNameFr: 'Inter 250cc',
    importName: 'Road Racing Inter 250cc',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1194,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter 250cc',
        nameEn: 'Inter 250cc',
        nameFr: 'Inter 250cc',
        shortNameDe: 'Inter 250cc',
        shortNameEn: 'Inter 250cc',
        shortNameFr: 'Inter 250cc',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 600, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-inter-125-cc',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter 125cc',
    commonNameEn: 'Inter 125cc',
    commonNameFr: 'Inter 125cc',
    importName: 'Road Racing Inter 125cc',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1195,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter 125cc',
        nameEn: 'Inter 125cc',
        nameFr: 'Inter 125cc',
        shortNameDe: 'Inter 125cc',
        shortNameEn: 'Inter 125cc',
        shortNameFr: 'Inter 125cc',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 440, to: '2007-01-01', licenseType: 'international' },
          { association: 'fms', value: 600, from: '2006-12-31', licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-inter-sidecar-pilot',
    active: true,
    archived: false,
    sidecar: true,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Sidecar Pilot',
    commonNameEn: 'Inter Sidecar Pilot',
    commonNameFr: 'Inter Sidecar Pilot',
    importName: 'Road Racing Inter Sidecar Pilot',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1196,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Sidecar Pilot',
        nameEn: 'Inter Sidecar Pilot',
        nameFr: 'Inter Sidecar Pilot',
        shortNameDe: 'Inter Sidecar Pilot',
        shortNameEn: 'Inter Sidecar Pilot',
        shortNameFr: 'Inter Sidecar Pilot',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 600, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-inter-sidecar-passager',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Sidecar Passager',
    commonNameEn: 'Inter Sidecar Passager',
    commonNameFr: 'Inter Sidecar Passager',
    importName: 'Road Racing Inter Sidecar Passager',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1197,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Sidecar Passager',
        nameEn: 'Inter Sidecar Passager',
        nameFr: 'Inter Sidecar Passager',
        shortNameDe: 'Inter Sidecar Passager',
        shortNameEn: 'Inter Sidecar Passager',
        shortNameFr: 'Inter Sidecar Passager',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 500, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-inter-mini-road-racing',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Mini Road Racing',
    commonNameEn: 'Inter Mini Road Racing',
    commonNameFr: 'Inter Mini Road Racing',
    importName: 'Road Racing Inter Mini Road Racing',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1198,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Mini Road Racing',
        nameEn: 'Inter Mini Road Racing',
        nameFr: 'Inter Mini Road Racing',
        shortNameDe: 'Inter Mini Road Racing',
        shortNameEn: 'Inter Mini Road Racing',
        shortNameFr: 'Inter Mini Road Racing',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 330, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-inter-endurance',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Endurance',
    commonNameEn: 'Inter Endurance',
    commonNameFr: 'Inter Endurance',
    importName: 'Road Racing Inter Endurance',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1199,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Endurance',
        nameEn: 'Inter Endurance',
        nameFr: 'Inter Endurance',
        shortNameDe: 'Inter Endurance',
        shortNameEn: 'Inter Endurance',
        shortNameFr: 'Inter Endurance',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 600, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-european-talent-cup',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'European Talent Cup',
    commonNameEn: 'European Talent Cup',
    commonNameFr: 'European Talent Cup',
    importName: 'Road Racing European Talent Cup',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1200,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'European Talent Cup',
        nameEn: 'European Talent Cup',
        nameFr: 'European Talent Cup',
        shortNameDe: 'European Talent Cup',
        shortNameEn: 'European Talent Cup',
        shortNameFr: 'European Talent Cup',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 600, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-inter-scooter',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Scooter',
    commonNameEn: 'Inter Scooter',
    commonNameFr: 'Inter Scooter',
    importName: 'Road Racing Inter Scooter',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1201,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Scooter',
        nameEn: 'Inter Scooter',
        nameFr: 'Inter Scooter',
        shortNameDe: 'Inter Scooter',
        shortNameEn: 'Inter Scooter',
        shortNameFr: 'Inter Scooter',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 600, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-inter-pitbike',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Pitbike',
    commonNameEn: 'Inter Pitbike',
    commonNameFr: 'Inter Pitbike',
    importName: 'Road Racing Inter Pitbike',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1202,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Pitbike',
        nameEn: 'Inter Pitbike',
        nameFr: 'Inter Pitbike',
        shortNameDe: 'Inter Pitbike',
        shortNameEn: 'Inter Pitbike',
        shortNameFr: 'Inter Pitbike',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 600, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-supersport-ssp',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Swiss Moto Supersport SSP',
    commonNameEn: 'Swiss Moto Supersport SSP',
    commonNameFr: 'Swiss Moto Supersport SSP',
    importName: 'Road Racing Supersport',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1278,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss Moto Supersport SSP',
        nameEn: 'Swiss Moto Supersport SSP',
        nameFr: 'Swiss Moto Supersport SSP',
        shortNameDe: 'Swiss Moto Supersport SSP',
        shortNameEn: 'Swiss Moto Supersport SSP',
        shortNameFr: 'Swiss Moto Supersport SSP',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 450, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '2009',
        priceHintFr: '2009',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-superbike-sbk',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Swiss Moto Superbike SBK',
    commonNameEn: 'Swiss Moto Superbike SBK',
    commonNameFr: 'Swiss Moto Superbike SBK',
    importName: 'Road Racing Superbike',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1279,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss Moto Superbike SBK',
        nameEn: 'Swiss Moto Superbike SBK',
        nameFr: 'Swiss Moto Superbike SBK',
        shortNameDe: 'Swiss Moto Superbike SBK',
        shortNameEn: 'Swiss Moto Superbike SBK',
        shortNameFr: 'Swiss Moto Superbike SBK',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 600, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '2007',
        priceHintFr: '2007',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-dragster-fim-drag-bike-wold-cup',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'dragster',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Drag Bike Wold Cup',
    commonNameEn: 'FIM Drag Bike Wold Cup',
    commonNameFr: 'FIM Drag Bike Wold Cup',
    importName: 'Dragster/Sprinter FIM Drag Bike Wold Cup',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1203,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Drag Bike Wold Cup',
        nameEn: 'FIM Drag Bike Wold Cup',
        nameFr: 'FIM Drag Bike Wold Cup',
        shortNameDe: 'FIM Drag Bike Wold Cup',
        shortNameEn: 'FIM Drag Bike Wold Cup',
        shortNameFr: 'FIM Drag Bike Wold Cup',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1170, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 620,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-dragster-inter-drag-bike',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'dragster',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Drag Bike',
    commonNameEn: 'Inter Drag Bike',
    commonNameFr: 'Inter Drag Bike',
    importName: 'Dragster/Sprinter Inter Drag Bike',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1204,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Drag Bike',
        nameEn: 'Inter Drag Bike',
        nameFr: 'Inter Drag Bike',
        shortNameDe: 'Inter Drag Bike',
        shortNameEn: 'Inter Drag Bike',
        shortNameFr: 'Inter Drag Bike',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 600, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-ice-race-fim-ice-race-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'iceRace',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Ice-Race WM/CM',
    commonNameEn: 'FIM Ice-Race WM/CM',
    commonNameFr: 'FIM Ice-Race WM/CM',
    importName: 'Ice-Race FIM Ice-Race WM/CM',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1205,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Ice-Race WM/CM',
        nameEn: 'FIM Ice-Race WM/CM',
        nameFr: 'FIM Ice-Race WM/CM',
        shortNameDe: 'FIM Ice-Race WM/CM',
        shortNameEn: 'FIM Ice-Race WM/CM',
        shortNameFr: 'FIM Ice-Race WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1350, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 960,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-ice-race-fim-eu-ice-race-em-ce',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'iceRace',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM EU Ice-Race EM/CE',
    commonNameEn: 'FIM EU Ice-Race EM/CE',
    commonNameFr: 'FIM EU Ice-Race EM/CE',
    importName: 'Ice-Race FIM EU Ice-Race EM/CE',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1206,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM EU Ice-Race EM/CE',
        nameEn: 'FIM EU Ice-Race EM/CE',
        nameFr: 'FIM EU Ice-Race EM/CE',
        shortNameDe: 'FIM EU Ice-Race EM/CE',
        shortNameEn: 'FIM EU Ice-Race EM/CE',
        shortNameFr: 'FIM EU Ice-Race EM/CE',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 665, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 275,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-ice-race-inter-ice-race',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'iceRace',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Ice-Race',
    commonNameEn: 'Inter Ice-Race',
    commonNameFr: 'Inter Ice-Race',
    importName: 'Ice-Race Inter Ice-Race',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1207,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Ice-Race',
        nameEn: 'Inter Ice-Race',
        nameFr: 'Inter Ice-Race',
        shortNameDe: 'Inter Ice-Race',
        shortNameEn: 'Inter Ice-Race',
        shortNameFr: 'Inter Ice-Race',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 440, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-snx-fim-snx-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'snx',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIM SNX WM/CM',
    commonNameEn: 'FIM SNX WM/CM',
    commonNameFr: 'FIM SNX WM/CM',
    importName: 'Ice-Race FIM SNX WM/CM',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1208,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM SNX WM/CM',
        nameEn: 'FIM SNX WM/CM',
        nameFr: 'FIM SNX WM/CM',
        shortNameDe: 'FIM SNX WM/CM',
        shortNameEn: 'FIM SNX WM/CM',
        shortNameFr: 'FIM SNX WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1070, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 680,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-snx-fim-snx-wm-cm-women',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'snx',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM SNX WM/CM Women',
    commonNameEn: 'FIM SNX WM/CM Women',
    commonNameFr: 'FIM SNX WM/CM Women',
    importName: 'Ice-Race FIM SNX WM/CM Women',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1209,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM SNX WM/CM Women',
        nameEn: 'FIM SNX WM/CM Women',
        nameFr: 'FIM SNX WM/CM Women',
        shortNameDe: 'FIM SNX WM/CM Women',
        shortNameEn: 'FIM SNX WM/CM Women',
        shortNameFr: 'FIM SNX WM/CM Women',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'international' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 615, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 225,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: false,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-snx-fime-snx-em-ce',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'snx',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIME SNX EM/CE',
    commonNameEn: 'FIME SNX EM/CE',
    commonNameFr: 'FIME SNX EM/CE',
    importName: 'SNX FIME SNX EM/CE',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1210,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME SNX EM/CE',
        nameEn: 'FIME SNX EM/CE',
        nameFr: 'FIME SNX EM/CE',
        shortNameDe: 'FIME SNX EM/CE',
        shortNameEn: 'FIME SNX EM/CE',
        shortNameFr: 'FIME SNX EM/CE',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 615, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 210,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-snx-inter-snx',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'snx',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Inter SNX',
    commonNameEn: 'Inter SNX',
    commonNameFr: 'Inter SNX',
    importName: 'SNX Inter SNX',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1211,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter SNX',
        nameEn: 'Inter SNX',
        nameFr: 'Inter SNX',
        shortNameDe: 'Inter SNX',
        shortNameEn: 'Inter SNX',
        shortNameFr: 'Inter SNX',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 390, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-snx-elite-open',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'snx',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Elite Open',
    commonNameEn: 'Elite Open',
    commonNameFr: 'Elite Open',
    importName: 'SNX Elite Open',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1212,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Elite Open',
        nameEn: 'Elite Open',
        nameFr: 'Elite Open',
        shortNameDe: 'Elite Open',
        shortNameEn: 'Elite Open',
        shortNameFr: 'Elite Open',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 220, licenseType: 'national' },
          { association: 'fms', value: 350, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 70,
        discountInscriptionWithLicenseTwoDays: 20,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-snx-amateur-open',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'snx',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Amateur Open',
    commonNameEn: 'Amateur Open',
    commonNameFr: 'Amateur Open',
    importName: 'SNX Amateur Open',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1213,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Amateur Open',
        nameEn: 'Amateur Open',
        nameFr: 'Amateur Open',
        shortNameDe: 'Amateur Open',
        shortNameEn: 'Amateur Open',
        shortNameFr: 'Amateur Open',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 220, licenseType: 'national' },
          { association: 'fms', value: 350, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 70,
        discountInscriptionWithLicenseTwoDays: 20,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-snx-senior-open',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'snx',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Senior Open',
    commonNameEn: 'Senior Open',
    commonNameFr: 'Senior Open',
    importName: 'SNX Senior Open',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1214,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Senior Open',
        nameEn: 'Senior Open',
        nameFr: 'Senior Open',
        shortNameDe: 'Senior Open',
        shortNameEn: 'Senior Open',
        shortNameFr: 'Senior Open',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 220, licenseType: 'national' },
          { association: 'fms', value: 350, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 70,
        discountInscriptionWithLicenseTwoDays: 20,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-snx-damen-open',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'snx',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Damen Open',
    commonNameEn: 'Damen Open',
    commonNameFr: 'Damen Open',
    importName: 'SNX Damen Open',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1215,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Damen Open',
        nameEn: 'Damen Open',
        nameFr: 'Damen Open',
        shortNameDe: 'Damen Open',
        shortNameEn: 'Damen Open',
        shortNameFr: 'Damen Open',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 220, licenseType: 'national' },
          { association: 'fms', value: 350, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 70,
        discountInscriptionWithLicenseTwoDays: 20,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-track-fim-track-racing-world-championship',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'track',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Track Racing World Championship',
    commonNameEn: 'FIM Track Racing World Championship',
    commonNameFr: 'FIM Track Racing World Championship',
    importName: 'Track FIM Track Racing World Championship',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1216,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Track Racing World Championship',
        nameEn: 'FIM Track Racing World Championship',
        nameFr: 'FIM Track Racing World Championship',
        shortNameDe: 'FIM Track Racing World Championship',
        shortNameEn: 'FIM Track Racing World Championship',
        shortNameFr: 'FIM Track Racing World Championship',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1510, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 960,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-track-fim-flat-track-world-championship',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'track',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Flat Track World Championship',
    commonNameEn: 'FIM Flat Track World Championship',
    commonNameFr: 'FIM Flat Track World Championship',
    importName: 'Track FIM Flat Track World Championship',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1217,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Flat Track World Championship',
        nameEn: 'FIM Flat Track World Championship',
        nameFr: 'FIM Flat Track World Championship',
        shortNameDe: 'FIM Flat Track World Championship',
        shortNameEn: 'FIM Flat Track World Championship',
        shortNameFr: 'FIM Flat Track World Championship',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 825, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 275,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-track-fime-track-racing',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'track',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Track Racing',
    commonNameEn: 'FIME Track Racing',
    commonNameFr: 'FIME Track Racing',
    importName: 'Track FIME Track Racing',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1218,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Track Racing',
        nameEn: 'FIME Track Racing',
        nameFr: 'FIME Track Racing',
        shortNameDe: 'FIME Track Racing',
        shortNameEn: 'FIME Track Racing',
        shortNameFr: 'FIME Track Racing',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 825, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 275,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-track-fime-speedway',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'track',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Speedway',
    commonNameEn: 'FIME Speedway',
    commonNameFr: 'FIME Speedway',
    importName: 'Track FIME Speedway',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1219,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Speedway',
        nameEn: 'FIME Speedway',
        nameFr: 'FIME Speedway',
        shortNameDe: 'FIME Speedway',
        shortNameEn: 'FIME Speedway',
        shortNameFr: 'FIME Speedway',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 825, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 275,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-track-inter-speedway-track',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'track',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Speedway/Track',
    commonNameEn: 'Inter Speedway/Track',
    commonNameFr: 'Inter Speedway/Track',
    importName: 'Track Inter Speedway/Track',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1220,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Speedway/Track',
        nameEn: 'Inter Speedway/Track',
        nameFr: 'Inter Speedway/Track',
        shortNameDe: 'Inter Speedway/Track',
        shortNameEn: 'Inter Speedway/Track',
        shortNameFr: 'Inter Speedway/Track',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 600, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-other-fms-fun-sport',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'otherFms',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Fun Sport',
    commonNameEn: 'Fun Sport',
    commonNameFr: 'Fun Sport',
    importName: 'Fun Sport',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1221,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Fun Sport',
        nameEn: 'Fun Sport',
        nameFr: 'Fun Sport',
        shortNameDe: 'Fun Sport',
        shortNameEn: 'Fun Sport',
        shortNameFr: 'Fun Sport',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 110, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-other-fms-inter-one-event-all-disciplines',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'otherFms',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter One Event all disciplines',
    commonNameEn: 'Inter One Event all disciplines',
    commonNameFr: 'Inter One Event all disciplines',
    importName: 'Inter One Event all disciplines',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1222,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter One Event all disciplines',
        nameEn: 'Inter One Event all disciplines',
        nameFr: 'Inter One Event all disciplines',
        shortNameDe: 'Inter One Event all disciplines',
        shortNameEn: 'Inter One Event all disciplines',
        shortNameFr: 'Inter One Event all disciplines',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 110, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 60,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-other-fms-fime-one-event-all-disciplines',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'otherFms',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME One Event all disciplines',
    commonNameEn: 'FIME One Event all disciplines',
    commonNameFr: 'FIME One Event all disciplines',
    importName: 'FIME One Event all disciplines',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1223,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME One Event all disciplines',
        nameEn: 'FIME One Event all disciplines',
        nameFr: 'FIME One Event all disciplines',
        shortNameDe: 'FIME One Event all disciplines',
        shortNameEn: 'FIME One Event all disciplines',
        shortNameFr: 'FIME One Event all disciplines',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 222, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 132,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-other-fms-fime-promosport-one-event-all-disciplines',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'otherFms',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Promosport One Event all disciplines',
    commonNameEn: 'FIME Promosport One Event all disciplines',
    commonNameFr: 'FIME Promosport One Event all disciplines',
    importName: 'FIME Promosport One Event all disciplines',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1224,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Promosport One Event all disciplines',
        nameEn: 'FIME Promosport One Event all disciplines',
        nameFr: 'FIME Promosport One Event all disciplines',
        shortNameDe: 'FIME Promosport One Event all disciplines',
        shortNameEn: 'FIME Promosport One Event all disciplines',
        shortNameFr: 'FIME Promosport One Event all disciplines',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 150, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 60,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-fim-superbike-supersport-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Superbike/Supersport One Event WM/CM',
    commonNameEn: 'FIM Superbike/Supersport One Event WM/CM',
    commonNameFr: 'FIM Superbike/Supersport One Event WM/CM',
    importName: 'FIM Superbike/Supersport One Event WM/CM',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1225,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Superbike/Supersport One Event WM/CM',
        nameEn: 'FIM Superbike/Supersport One Event WM/CM',
        nameFr: 'FIM Superbike/Supersport One Event WM/CM',
        shortNameDe: 'FIM Superbike/Supersport One Event WM/CM',
        shortNameEn: 'FIM Superbike/Supersport One Event WM/CM',
        shortNameFr: 'FIM Superbike/Supersport One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 770, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 680,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Kategorie, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, category, date and location',
        hintFr: 'Annoncer le nom de la course, la catégorie, la date et le lieu',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Kategorie, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom de la course, la catégorie, la date et le lieu',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-fim-endurance-rider-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Endurance Rider One Event WM/CM',
    commonNameEn: 'FIM Endurance Rider One Event WM/CM',
    commonNameFr: 'FIM Endurance Rider One Event WM/CM',
    importName: 'FIM Endurance Rider One Event WM/CM',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1226,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Endurance Rider One Event WM/CM',
        nameEn: 'FIM Endurance Rider One Event WM/CM',
        nameFr: 'FIM Endurance Rider One Event WM/CM',
        shortNameDe: 'FIM Endurance Rider One Event WM/CM',
        shortNameEn: 'FIM Endurance Rider One Event WM/CM',
        shortNameFr: 'FIM Endurance Rider One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 315, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 225,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-fim-mxgp-mx-2-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM MXGP/ MX2 One Event WM/CM',
    commonNameEn: 'FIM MXGP/ MX2 One Event WM/CM',
    commonNameFr: 'FIM MXGP/ MX2 One Event WM/CM',
    importName: 'FIM MXGP/ MX2 One Event WM/CM',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1227,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM MXGP/ MX2 One Event WM/CM',
        nameEn: 'FIM MXGP/ MX2 One Event WM/CM',
        nameFr: 'FIM MXGP/ MX2 One Event WM/CM',
        shortNameDe: 'FIM MXGP/ MX2 One Event WM/CM',
        shortNameEn: 'FIM MXGP/ MX2 One Event WM/CM',
        shortNameFr: 'FIM MXGP/ MX2 One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 545, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 455,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-fim-mx-junior-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM MX Junior One Event WM/CM',
    commonNameEn: 'FIM MX Junior One Event WM/CM',
    commonNameFr: 'FIM MX Junior One Event WM/CM',
    importName: 'FIM MX Junior One Event WM/CM',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1228,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM MX Junior One Event WM/CM',
        nameEn: 'FIM MX Junior One Event WM/CM',
        nameFr: 'FIM MX Junior One Event WM/CM',
        shortNameDe: 'FIM MX Junior One Event WM/CM',
        shortNameEn: 'FIM MX Junior One Event WM/CM',
        shortNameFr: 'FIM MX Junior One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 205, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 115,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-fim-wmx-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM WMX One Event WM/CM',
    commonNameEn: 'FIM WMX One Event WM/CM',
    commonNameFr: 'FIM WMX One Event WM/CM',
    importName: 'FIM WMX One Event WM/CM',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1229,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM WMX One Event WM/CM',
        nameEn: 'FIM WMX One Event WM/CM',
        nameFr: 'FIM WMX One Event WM/CM',
        shortNameDe: 'FIM WMX One Event WM/CM',
        shortNameEn: 'FIM WMX One Event WM/CM',
        shortNameFr: 'FIM WMX One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 205, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 115,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-fim-mx-sidecar-pilot-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: true,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM MX Sidecar Pilot One Event WM/CM',
    commonNameEn: 'FIM MX Sidecar Pilot One Event WM/CM',
    commonNameFr: 'FIM MX Sidecar Pilot One Event WM/CM',
    importName: 'FIM MX Sidecar Pilot One Event WM/CM',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1230,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM MX Sidecar Pilot One Event WM/CM',
        nameEn: 'FIM MX Sidecar Pilot One Event WM/CM',
        nameFr: 'FIM MX Sidecar Pilot One Event WM/CM',
        shortNameDe: 'FIM MX Sidecar Pilot One Event WM/CM',
        shortNameEn: 'FIM MX Sidecar Pilot One Event WM/CM',
        shortNameFr: 'FIM MX Sidecar Pilot One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 205, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 115,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-fim-mx-sidecar-passenger-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: true,
    type: 'motocross',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM MX Sidecar Passenger One Event WM/CM',
    commonNameEn: 'FIM MX Sidecar Passenger One Event WM/CM',
    commonNameFr: 'FIM MX Sidecar Passenger One Event WM/CM',
    importName: 'FIM MX Sidecar Passenger One Event WM/CM',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1231,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM MX Sidecar Passenger One Event WM/CM',
        nameEn: 'FIM MX Sidecar Passenger One Event WM/CM',
        nameFr: 'FIM MX Sidecar Passenger One Event WM/CM',
        shortNameDe: 'FIM MX Sidecar Passenger One Event WM/CM',
        shortNameEn: 'FIM MX Sidecar Passenger One Event WM/CM',
        shortNameFr: 'FIM MX Sidecar Passenger One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 145, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 55,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-supermoto-fim-supermoto-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Supermoto One Event WM/CM',
    commonNameEn: 'FIM Supermoto One Event WM/CM',
    commonNameFr: 'FIM Supermoto One Event WM/CM',
    importName: 'FIM Supermoto One Event WM/CM',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1232,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Supermoto One Event WM/CM',
        nameEn: 'FIM Supermoto One Event WM/CM',
        nameFr: 'FIM Supermoto One Event WM/CM',
        shortNameDe: 'FIM Supermoto One Event WM/CM',
        shortNameEn: 'FIM Supermoto One Event WM/CM',
        shortNameFr: 'FIM Supermoto One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 275, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 185,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-snx-fim-snx-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'snx',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM SNX One Event WM/CM',
    commonNameEn: 'FIM SNX One Event WM/CM',
    commonNameFr: 'FIM SNX One Event WM/CM',
    importName: 'FIM SNX One Event WM/CM',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1233,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM SNX One Event WM/CM',
        nameEn: 'FIM SNX One Event WM/CM',
        nameFr: 'FIM SNX One Event WM/CM',
        shortNameDe: 'FIM SNX One Event WM/CM',
        shortNameEn: 'FIM SNX One Event WM/CM',
        shortNameFr: 'FIM SNX One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 430, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 340,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-snx-fim-snx-women-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'snx',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM SNX Women One Event WM/CM',
    commonNameEn: 'FIM SNX Women One Event WM/CM',
    commonNameFr: 'FIM SNX Women One Event WM/CM',
    importName: 'FIM SNX Women One Event WM/CM',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1234,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM SNX Women One Event WM/CM',
        nameEn: 'FIM SNX Women One Event WM/CM',
        nameFr: 'FIM SNX Women One Event WM/CM',
        shortNameDe: 'FIM SNX Women One Event WM/CM',
        shortNameEn: 'FIM SNX Women One Event WM/CM',
        shortNameFr: 'FIM SNX Women One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 205, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 115,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-trial-fim-trial-trial-de-nation-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Trial, Trial de Nation One Event WM/CM',
    commonNameEn: 'FIM Trial, Trial de Nation One Event WM/CM',
    commonNameFr: 'FIM Trial, Trial de Nation One Event WM/CM',
    importName: 'FIM Trial, Trial de Nation One Event WM/CM',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1235,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Trial, Trial de Nation One Event WM/CM',
        nameEn: 'FIM Trial, Trial de Nation One Event WM/CM',
        nameFr: 'FIM Trial, Trial de Nation One Event WM/CM',
        shortNameDe: 'FIM Trial, Trial de Nation One Event WM/CM',
        shortNameEn: 'FIM Trial, Trial de Nation One Event WM/CM',
        shortNameFr: 'FIM Trial, Trial de Nation One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 315, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 225,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-trial-fime-trial-assistant-one-event',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Trial Assistant One Event',
    commonNameEn: 'FIME Trial Assistant One Event',
    commonNameFr: 'FIME Trial Assistant One Event',
    importName: 'Trial FIME Trial Assistant One Event',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1236,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Trial Assistant One Event',
        nameEn: 'FIME Trial Assistant One Event',
        nameFr: 'FIME Trial Assistant One Event',
        shortNameDe: 'FIME Trial Assistant One Event',
        shortNameEn: 'FIME Trial Assistant One Event',
        shortNameFr: 'FIME Trial Assistant One Event',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'international' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 145, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 55,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-trial-fim-trial-assistent-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Trial Assistent One Event WM/CM',
    commonNameEn: 'FIM Trial Assistent One Event WM/CM',
    commonNameFr: 'FIM Trial Assistent One Event WM/CM',
    importName: 'FIM Trial Assistent One Event WM/CM',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1237,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Trial Assistent One Event WM/CM',
        nameEn: 'FIM Trial Assistent One Event WM/CM',
        nameFr: 'FIM Trial Assistent One Event WM/CM',
        shortNameDe: 'FIM Trial Assistent One Event WM/CM',
        shortNameEn: 'FIM Trial Assistent One Event WM/CM',
        shortNameFr: 'FIM Trial Assistent One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'international' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 145, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: false,
        priceAdditionalLicense: 55,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-enduro-fim-enduro-isde-world-trophy-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Enduro & ISDE World Trophy One Event WM/CM',
    commonNameEn: 'FIM Enduro & ISDE World Trophy One Event WM/CM',
    commonNameFr: 'FIM Enduro & ISDE World Trophy One Event WM/CM',
    importName: 'FIM Enduro & ISDE World Trophy One Event WM/CM',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1238,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Enduro & ISDE World Trophy One Event WM/CM',
        nameEn: 'FIM Enduro & ISDE World Trophy One Event WM/CM',
        nameFr: 'FIM Enduro & ISDE World Trophy One Event WM/CM',
        shortNameDe: 'FIM Enduro & ISDE World Trophy One Event WM/CM',
        shortNameEn: 'FIM Enduro & ISDE World Trophy One Event WM/CM',
        shortNameFr: 'FIM Enduro & ISDE World Trophy One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 315, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 225,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-enduro-fim-isde-womens-clubs-team-one-event',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM ISDE Womens, Clubs Team One Event',
    commonNameEn: 'FIM ISDE Womens, Clubs Team One Event',
    commonNameFr: 'FIM ISDE Womens, Clubs Team One Event',
    importName: 'FIM ISDE Womens, Clubs Team One Event',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1239,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM ISDE Womens, Clubs Team One Event',
        nameEn: 'FIM ISDE Womens, Clubs Team One Event',
        nameFr: 'FIM ISDE Womens, Clubs Team One Event',
        shortNameDe: 'FIM ISDE Womens, Clubs Team One Event',
        shortNameEn: 'FIM ISDE Womens, Clubs Team One Event',
        shortNameFr: 'FIM ISDE Womens, Clubs Team One Event',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 205, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 115,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-enduro-fim-enduro-vintage-world-cup-fim-enduro-vintage-trophy-one-event',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Enduro Vintage World Cup / FIM Enduro Vintage Trophy One Event',
    commonNameEn: 'FIM Enduro Vintage World Cup / FIM Enduro Vintage Trophy One Event',
    commonNameFr: 'FIM Enduro Vintage World Cup / FIM Enduro Vintage Trophy One Event',
    importName: 'FIM Enduro Vintage World Cup / FIM Enduro Vintage Trophy One Event',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1240,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Enduro Vintage World Cup / FIM Enduro Vintage Trophy One Event',
        nameEn: 'FIM Enduro Vintage World Cup / FIM Enduro Vintage Trophy One Event',
        nameFr: 'FIM Enduro Vintage World Cup / FIM Enduro Vintage Trophy One Event',
        shortNameDe: 'FIM Enduro Vintage World Cup / FIM Enduro Vintage Trophy One Event',
        shortNameEn: 'FIM Enduro Vintage World Cup / FIM Enduro Vintage Trophy One Event',
        shortNameFr: 'FIM Enduro Vintage World Cup / FIM Enduro Vintage Trophy One Event',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 205, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 115,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-enduro-fim-cross-country-rally-one-event',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Cross Country RallyGP One Event',
    commonNameEn: 'FIM Cross Country RallyGP One Event',
    commonNameFr: 'FIM Cross Country RallyGP One Event',
    importName: 'FIM Cross Country RallyGP One Event',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1241,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Cross Country RallyGP One Event',
        nameEn: 'FIM Cross Country RallyGP One Event',
        nameFr: 'FIM Cross Country RallyGP One Event',
        shortNameDe: 'FIM Cross Country RallyGP One Event',
        shortNameEn: 'FIM Cross Country RallyGP One Event',
        shortNameFr: 'FIM Cross Country RallyGP One Event',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 770, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 680,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-enduro-fim-cross-country-rally-raid-rally-2-one-event',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Cross Country Rally-Raid & Rally2 One Event',
    commonNameEn: 'FIM Cross Country Rally-Raid & Rally2 One Event',
    commonNameFr: 'FIM Cross Country Rally-Raid & Rally2 One Event',
    importName: 'FIM Cross Country Rally-Raid & Rally2 One Event',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1242,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Cross Country Rally-Raid & Rally2 One Event',
        nameEn: 'FIM Cross Country Rally-Raid & Rally2 One Event',
        nameFr: 'FIM Cross Country Rally-Raid & Rally2 One Event',
        shortNameDe: 'FIM Cross Country Rally-Raid & Rally2 One Event',
        shortNameEn: 'FIM Cross Country Rally-Raid & Rally2 One Event',
        shortNameFr: 'FIM Cross Country Rally-Raid & Rally2 One Event',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 545, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 455,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-enduro-fim-cross-country-rally-raid-rally-3-one-event',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Cross Country Rally-Raid & Rally3 One Event',
    commonNameEn: 'FIM Cross Country Rally-Raid & Rally3 One Event',
    commonNameFr: 'FIM Cross Country Rally-Raid & Rally3 One Event',
    importName: 'FIM Cross Country Rally-Raid & Rally3 One Event',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1243,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Cross Country Rally-Raid & Rally3 One Event',
        nameEn: 'FIM Cross Country Rally-Raid & Rally3 One Event',
        nameFr: 'FIM Cross Country Rally-Raid & Rally3 One Event',
        shortNameDe: 'FIM Cross Country Rally-Raid & Rally3 One Event',
        shortNameEn: 'FIM Cross Country Rally-Raid & Rally3 One Event',
        shortNameFr: 'FIM Cross Country Rally-Raid & Rally3 One Event',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 205, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 115,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-other-fms-fim-e-xplorer-world-cup-pne-event',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'otherFms',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM E-Xplorer World Cup One Event',
    commonNameEn: 'FIM E-Xplorer World Cup One Event',
    commonNameFr: 'FIM E-Xplorer World Cup One Event',
    importName: 'FIM E-Xplorer World Cup One Event',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1244,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM E-Xplorer World Cup One Event',
        nameEn: 'FIM E-Xplorer World Cup One Event',
        nameFr: 'FIM E-Xplorer World Cup One Event',
        shortNameDe: 'FIM E-Xplorer World Cup One Event',
        shortNameEn: 'FIM E-Xplorer World Cup One Event',
        shortNameFr: 'FIM E-Xplorer World Cup One Event',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 315, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 225,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-other-fms-fim-land-speed-world-record',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'otherFms',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Land Speed World Record',
    commonNameEn: 'FIM Land Speed World Record',
    commonNameFr: 'FIM Land Speed World Record',
    importName: 'FIM Land Speed World Record',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1245,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Land Speed World Record',
        nameEn: 'FIM Land Speed World Record',
        nameFr: 'FIM Land Speed World Record',
        shortNameDe: 'FIM Land Speed World Record',
        shortNameEn: 'FIM Land Speed World Record',
        shortNameFr: 'FIM Land Speed World Record',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 315, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 225,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-ice-race-fim-track-ice-speedway-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'iceRace',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Track, Ice Speedway One Event WM/CM',
    commonNameEn: 'FIM Track, Ice Speedway One Event WM/CM',
    commonNameFr: 'FIM Track, Ice Speedway One Event WM/CM',
    importName: 'FIM Track, Ice Speedway One Event WM/CM',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1246,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Track, Ice Speedway One Event WM/CM',
        nameEn: 'FIM Track, Ice Speedway One Event WM/CM',
        nameFr: 'FIM Track, Ice Speedway One Event WM/CM',
        shortNameDe: 'FIM Track, Ice Speedway One Event WM/CM',
        shortNameEn: 'FIM Track, Ice Speedway One Event WM/CM',
        shortNameFr: 'FIM Track, Ice Speedway One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 315, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 225,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-fim-junior-gp-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM JuniorGP One Event WM/CM',
    commonNameEn: 'FIM JuniorGP One Event WM/CM',
    commonNameFr: 'FIM JuniorGP One Event WM/CM',
    importName: 'FIM JuniorGP One Event WM/CM',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1247,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM JuniorGP One Event WM/CM',
        nameEn: 'FIM JuniorGP One Event WM/CM',
        nameFr: 'FIM JuniorGP One Event WM/CM',
        shortNameDe: 'FIM JuniorGP One Event WM/CM',
        shortNameEn: 'FIM JuniorGP One Event WM/CM',
        shortNameFr: 'FIM JuniorGP One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 205, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 115,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-trial-fim-trial-one-event-vintage-trophy',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Trial, One Event Vintage Trophy',
    commonNameEn: 'FIM Trial, One Event Vintage Trophy',
    commonNameFr: 'FIM Trial, One Event Vintage Trophy',
    importName: 'FIM Trial, One Event Vintage Trophy',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1248,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Trial, One Event Vintage Trophy',
        nameEn: 'FIM Trial, One Event Vintage Trophy',
        nameFr: 'FIM Trial, One Event Vintage Trophy',
        shortNameDe: 'FIM Trial, One Event Vintage Trophy',
        shortNameEn: 'FIM Trial, One Event Vintage Trophy',
        shortNameFr: 'FIM Trial, One Event Vintage Trophy',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 145, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 55,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-trial-fim-trial-women-wm-cm-one-event',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Trial Women WM/CM One Event',
    commonNameEn: 'FIM Trial Women WM/CM One Event',
    commonNameFr: 'FIM Trial Women WM/CM One Event',
    importName: 'FIM Trial Women WM/CM One Event',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1249,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Trial Women WM/CM One Event',
        nameEn: 'FIM Trial Women WM/CM One Event',
        nameFr: 'FIM Trial Women WM/CM One Event',
        shortNameDe: 'FIM Trial Women WM/CM One Event',
        shortNameEn: 'FIM Trial Women WM/CM One Event',
        shortNameFr: 'FIM Trial Women WM/CM One Event',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 145, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 55,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-trial-fim-trial-des-nations-international',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Trial des Nations international',
    commonNameEn: 'FIM Trial des Nations international',
    commonNameFr: 'FIM Trial des Nations international',
    importName: 'FIM Trial des Nations international',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1250,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Trial des Nations international',
        nameEn: 'FIM Trial des Nations international',
        nameFr: 'FIM Trial des Nations international',
        shortNameDe: 'FIM Trial des Nations international',
        shortNameEn: 'FIM Trial des Nations international',
        shortNameFr: 'FIM Trial des Nations international',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 260, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 170,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-inter-moto-3-moto-4',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Moto3 / Moto4',
    commonNameEn: 'Inter Moto3 / Moto4',
    commonNameFr: 'Inter Moto3 / Moto4',
    importName: 'Road Racing Inter Moto3 / Moto4',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1251,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Moto3 / Moto4',
        nameEn: 'Inter Moto3 / Moto4',
        nameFr: 'Inter Moto3 / Moto4',
        shortNameDe: 'Inter Moto3 / Moto4',
        shortNameEn: 'Inter Moto3 / Moto4',
        shortNameFr: 'Inter Moto3 / Moto4',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 440, to: '2007-01-01', licenseType: 'international' },
          { association: 'fms', value: 600, from: '2006-12-31', licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-fim-sidecar-pilote-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: true,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Sidecar Pilote One Event WM/CM',
    commonNameEn: 'FIM Sidecar Pilote One Event WM/CM',
    commonNameFr: 'FIM Sidecar Pilote One Event WM/CM',
    importName: 'Road Racing FIM Sidecar Pilote One Event WM/CM',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1252,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Sidecar Pilote One Event WM/CM',
        nameEn: 'FIM Sidecar Pilote One Event WM/CM',
        nameFr: 'FIM Sidecar Pilote One Event WM/CM',
        shortNameDe: 'FIM Sidecar Pilote One Event WM/CM',
        shortNameEn: 'FIM Sidecar Pilote One Event WM/CM',
        shortNameFr: 'FIM Sidecar Pilote One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 205, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 115,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-fim-sidecar-passager-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Sidecar Passager One Event WM/CM',
    commonNameEn: 'FIM Sidecar Passager One Event WM/CM',
    commonNameFr: 'FIM Sidecar Passager One Event WM/CM',
    importName: 'Road Racing FIM Sidecar Passager One Event WM/CM',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1253,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Sidecar Passager One Event WM/CM',
        nameEn: 'FIM Sidecar Passager One Event WM/CM',
        nameFr: 'FIM Sidecar Passager One Event WM/CM',
        shortNameDe: 'FIM Sidecar Passager One Event WM/CM',
        shortNameEn: 'FIM Sidecar Passager One Event WM/CM',
        shortNameFr: 'FIM Sidecar Passager One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 145, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 55,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-trial-fim-x-trial-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM X-Trial One Event WM/CM',
    commonNameEn: 'FIM X-Trial One Event WM/CM',
    commonNameFr: 'FIM X-Trial One Event WM/CM',
    importName: 'FIM X-Trial One Event WM/CM',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1254,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM X-Trial One Event WM/CM',
        nameEn: 'FIM X-Trial One Event WM/CM',
        nameFr: 'FIM X-Trial One Event WM/CM',
        shortNameDe: 'FIM X-Trial One Event WM/CM',
        shortNameEn: 'FIM X-Trial One Event WM/CM',
        shortNameFr: 'FIM X-Trial One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 315, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 225,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Announce race name, date and location',
        hintFr: 'Annoncer le nom, la date et le lieu de la course',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Annoncer le nom, la date et le lieu de la course',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-motocross-swiss-moto-e-cup',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Swiss Moto E-Cup',
    commonNameEn: 'Swiss Moto E-Cup',
    commonNameFr: 'Swiss Moto E-Cup',
    importName: 'Swiss Moto E-Cup',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'E-Cup',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1255,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss Moto E-Cup',
        nameEn: 'Swiss Moto E-Cup',
        nameFr: 'Swiss Moto E-Cup',
        shortNameDe: 'Swiss Moto E-Cup',
        shortNameEn: 'Swiss Moto E-Cup',
        shortNameFr: 'Swiss Moto E-Cup',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'E-Cup',
        startListName: 'E-Cup',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 180, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2008 und älter',
        hintEn: 'Born in 2008 and older',
        hintFr: 'Année de naissance 2008 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-supermoto-fime-supermoto-junior-em',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIME Supermoto Junior EM',
    commonNameEn: 'FIME Supermoto Junior EM',
    commonNameFr: 'FIME Supermoto Junior EM',
    importName: 'SM FIME Supermoto Junior EM',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1256,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Supermoto Junior EM',
        nameEn: 'FIME Supermoto Junior EM',
        nameFr: 'FIME Supermoto Junior EM',
        shortNameDe: 'FIME Supermoto Junior EM',
        shortNameEn: 'FIME Supermoto Junior EM',
        shortNameFr: 'FIME Supermoto Junior EM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 555, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 165,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2025-road-racing-yamalube-yamaha-r-7-cup',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Yamalube Yamaha R7 Cup',
    commonNameEn: 'Yamalube Yamaha R7 Cup',
    commonNameFr: 'Yamalube Yamaha R7 Cup',
    importName: 'Road Racing Yamalube Yamaha R7 Cup',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1257,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Yamalube Yamaha R7 Cup',
        nameEn: 'Yamalube Yamaha R7 Cup',
        nameFr: 'Yamalube Yamaha R7 Cup',
        shortNameDe: 'Yamalube Yamaha R7 Cup',
        shortNameEn: 'Yamalube Yamaha R7 Cup',
        shortNameFr: 'Yamalube Yamaha R7 Cup',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#2a3b57',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 600, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2025-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2025-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'afm-2025-motocross-rookie',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Rookie',
    commonNameEn: 'Rookie',
    commonNameFr: 'Rookie',
    importName: 'Rookie',
    year: 2025,
    enlistWithoutLicense: false,
    startListName: 'Rookie',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1081,
    associations: ['afm'],
    associationSpecificDetails: [
      {
        nameDe: 'Rookie',
        nameEn: 'Rookie',
        nameFr: 'Rookie',
        shortNameDe: 'Rookie',
        shortNameEn: 'Rookie',
        shortNameFr: 'Rookie',
        needsHealthCheck: [{ association: 'afm', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Rookie',
        startListName: 'Rookie',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#dc1d08',
        numberFixed: true,
        association: 'afm',
        prices: [{ association: 'afm', value: 150, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 150,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 70,
        discountInscriptionWithLicenseTwoDays: 30,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 10,
        surchargeAfter: '2025-02-28T23:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T23:59:59.999Z',
        hintDe: 'Ab 250cmm  4T',
        hintEn: 'Up to 250 ccm 4T',
        hintFr: 'Dès 25ccm 0 4T',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'afm-2025-motocross-mx-girls',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'MX Girls',
    commonNameEn: 'MX Girls',
    commonNameFr: 'MX Girls',
    importName: 'MX Girls',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'MX Girls',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1082,
    associations: ['afm'],
    associationSpecificDetails: [
      {
        nameDe: 'MX Girls',
        nameEn: 'MX Girls',
        nameFr: 'MX Girls',
        shortNameDe: 'MX Girls',
        shortNameEn: 'MX Girls',
        shortNameFr: 'MX Girls',
        needsHealthCheck: [{ association: 'afm', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MX Girls',
        startListName: 'MX Girls',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#dc1d08',
        numberFixed: true,
        association: 'afm',
        prices: [{ association: 'afm', value: 150, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 150,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 70,
        discountInscriptionWithLicenseTwoDays: 30,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 72,
        surcharge: 10,
        surchargeAfter: '2025-02-28T23:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T23:59:59.999Z',
        hintDe: 'Ab 125ccm',
        hintEn: 'From 125 ccm',
        hintFr: 'Dès 125  ccm',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 50,
        priceDayLicenseWithoutMemberTwoDays: 100,
        priceDayLicenseWithOtherLicense: 50,
        priceDayLicenseWithOtherLicenseTwoDays: 100,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'afm-2025-motocross-mx-3',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'MX3',
    commonNameEn: 'MX3',
    commonNameFr: 'MX3',
    importName: 'MX3 Senior',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'MX3',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1083,
    associations: ['afm'],
    associationSpecificDetails: [
      {
        nameDe: 'MX3',
        nameEn: 'MX3',
        nameFr: 'MX3',
        shortNameDe: 'MX3',
        shortNameEn: 'MX3',
        shortNameFr: 'MX3',
        needsHealthCheck: [{ association: 'afm', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MX3',
        startListName: 'MX3',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#dc1d08',
        numberFixed: true,
        association: 'afm',
        prices: [{ association: 'afm', value: 150, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 150,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 70,
        discountInscriptionWithLicenseTwoDays: 30,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 72,
        surcharge: 10,
        surchargeAfter: '2025-02-28T23:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T23:59:59.999Z',
        hintDe: 'Ab Jahrgang 1985 (40 Jahre) oder Älter',
        hintEn: 'From birth date 1985',
        hintFr: "Dès l' année 1985 (40 ans) ou plus",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 50,
        priceDayLicenseWithoutMemberTwoDays: 100,
        priceDayLicenseWithOtherLicense: 50,
        priceDayLicenseWithOtherLicenseTwoDays: 100,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'afm-2025-motocross-mx-senior',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'MX Senior',
    commonNameEn: 'MX Senior',
    commonNameFr: 'MX Senior',
    importName: 'MX3 Senior',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'MX Senior',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1084,
    associations: ['afm'],
    associationSpecificDetails: [
      {
        nameDe: 'MX Senior',
        nameEn: 'MX Senior',
        nameFr: 'MX Senior',
        shortNameDe: 'MX Senior',
        shortNameEn: 'MX Senior',
        shortNameFr: 'MX Senior',
        needsHealthCheck: [{ association: 'afm', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MX Senior',
        startListName: 'MX Senior',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#dc1d08',
        numberFixed: true,
        association: 'afm',
        prices: [{ association: 'afm', value: 150, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 150,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 70,
        discountInscriptionWithLicenseTwoDays: 30,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 72,
        surcharge: 10,
        surchargeAfter: '2025-02-28T23:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T23:59:59.999Z',
        hintDe: 'Ab Jahrgang 1975 (50 Jahre) oder Älter',
        hintEn: 'From birth date 1975',
        hintFr: "Dès l' année 1975 (40 ans) ou plus",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 50,
        priceDayLicenseWithoutMemberTwoDays: 100,
        priceDayLicenseWithOtherLicense: 50,
        priceDayLicenseWithOtherLicenseTwoDays: 100,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'afm-2025-motocross-mx-125',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'MX125',
    commonNameEn: 'MX125',
    commonNameFr: 'MX125',
    importName: 'MX125',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'MX125',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1085,
    associations: ['afm'],
    associationSpecificDetails: [
      {
        nameDe: 'MX125',
        nameEn: 'MX125',
        nameFr: 'MX125',
        shortNameDe: 'MX125',
        shortNameEn: 'MX125',
        shortNameFr: 'MX125',
        needsHealthCheck: [{ association: 'afm', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MX125',
        startListName: 'MX125',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#dc1d08',
        numberFixed: true,
        association: 'afm',
        prices: [{ association: 'afm', value: 150, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 150,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 70,
        discountInscriptionWithLicenseTwoDays: 30,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 72,
        surcharge: 10,
        surchargeAfter: '2025-02-28T23:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T23:59:59.999Z',
        hintDe: '125-150 ccm 2T',
        hintEn: '125-150 ccm 2T',
        hintFr: '125-150 ccm 2T',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 50,
        priceDayLicenseWithoutMemberTwoDays: 100,
        priceDayLicenseWithOtherLicense: 50,
        priceDayLicenseWithOtherLicenseTwoDays: 100,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'afm-2025-motocross-mx-250',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'MX250',
    commonNameEn: 'MX250',
    commonNameFr: 'MX250',
    importName: 'MX250',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'MX250',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1086,
    associations: ['afm'],
    associationSpecificDetails: [
      {
        nameDe: 'MX250',
        nameEn: 'MX250',
        nameFr: 'MX250',
        shortNameDe: 'MX250',
        shortNameEn: 'MX250',
        shortNameFr: 'MX250',
        needsHealthCheck: [{ association: 'afm', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MX250',
        startListName: 'MX250',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#dc1d08',
        numberFixed: true,
        association: 'afm',
        prices: [{ association: 'afm', value: 150, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 150,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 70,
        discountInscriptionWithLicenseTwoDays: 30,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 72,
        surcharge: 10,
        surchargeAfter: '2025-02-28T23:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T23:59:59.999Z',
        hintDe: 'Nur 250 ccm 4T',
        hintEn: 'Only 250 ccm 4T',
        hintFr: 'Uniquement 250 ccm 4T',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 50,
        priceDayLicenseWithoutMemberTwoDays: 100,
        priceDayLicenseWithOtherLicense: 50,
        priceDayLicenseWithOtherLicenseTwoDays: 100,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'afm-2025-motocross-mx-open',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'MXOpen',
    commonNameEn: 'MXOpen',
    commonNameFr: 'MXOpen',
    importName: 'MXOpen',
    year: 2025,
    enlistWithoutLicense: true,
    startListName: 'MXOpen',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 1087,
    associations: ['afm'],
    associationSpecificDetails: [
      {
        nameDe: 'MXOpen',
        nameEn: 'MXOpen',
        nameFr: 'MXOpen',
        shortNameDe: 'MXOpen',
        shortNameEn: 'MXOpen',
        shortNameFr: 'MXOpen',
        needsHealthCheck: [{ association: 'afm', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MXOpen',
        startListName: 'MXOpen',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#dc1d08',
        numberFixed: true,
        association: 'afm',
        prices: [{ association: 'afm', value: 150, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 150,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 70,
        discountInscriptionWithLicenseTwoDays: 30,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 72,
        surcharge: 10,
        surchargeAfter: '2025-02-28T23:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T23:59:59.999Z',
        hintDe: 'Ab 250 ccm',
        hintEn: 'From 250 ccm',
        hintFr: 'Dès 250 ccm',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 50,
        priceDayLicenseWithoutMemberTwoDays: 100,
        priceDayLicenseWithOtherLicense: 50,
        priceDayLicenseWithOtherLicenseTwoDays: 100,
      },
    ],
    categoryType: 'licenseCategory',
  },
] as const
